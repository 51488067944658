import React, { useState } from 'react';
import CodeInput from 'react-code-input';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { ChevronLeft } from 'tabler-icons-react';

import outmindLogo from '../../assets/outmind-logo.png';
import { useTranslations, useUpdateProfile, useUserProfile } from '../../hooks';
import {
  Card,
  CardContent,
  CircularProgress,
  Fab,
  IconButton,
  TextField,
  Typography,
} from '../../material';
import { AcceptTerms } from '../AcceptTerms';
import { UserProfileAvatar } from '../UserProfileAvatar';
import { WelcomeView } from '../WelcomeView';
import { useStyles } from './styles';

export const VIPAccessView: React.FC = (props) => {
  const classes = useStyles(props);

  const { data: me } = useUserProfile();

  const { t } = useTranslations();

  const [modifiedGivenName, setModifiedGivenName] = useState<string>();
  const [modifiedFamilyName, setModifiedFamilyName] = useState<string>();
  const [position, setPosition] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [accessCode, setAccessCode] = useState<string>();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);

  const familyName = modifiedFamilyName !== undefined ? modifiedFamilyName : me && me.familyName;
  const givenName = modifiedGivenName !== undefined ? modifiedGivenName : me && me.givenName;

  const modifiedProfile = {
    familyName,
    givenName,
    position,
  };

  const isValidFamilyName =
    familyName && familyName.match(/^[^0-9±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]{1,100}$/) !== null;
  const isValidGivenName =
    givenName && givenName.match(/^[^0-9±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]{1,100}$/) !== null;

  const continueButtonDisabled =
    !acceptedTerms || !isValidFamilyName || !isValidGivenName || isLoading;

  const { mutate: updateProfile } = useUpdateProfile();

  if (!me) return null;

  return (
    <WelcomeView withFooter>
      <Link to="/register">
        <div className={classes.imageLogoContainer}>
          <img alt="outmind-logo" src={outmindLogo} />
          <IconButton className={classes.chevronButton}>
            <ChevronLeft />
          </IconButton>
        </div>
      </Link>
      <Media query="(max-width: 959px)">
        {(matches) => (
          <>
            <div className={!matches ? classes.signinTitle : classes.signinTitleMobile}>
              {t('complete_your_information')}
            </div>
            <div className={!matches ? classes.signinHelper : classes.signinHelperMobile}>
              {t('complete_your_information_helper')}
            </div>
            <Card className={classes.userInfosCard} elevation={0}>
              <CardContent>
                <div className={classes.userAvatarContainer}>
                  <UserProfileAvatar big user={{ ...me, ...modifiedProfile }} />
                </div>
                <div className={classes.userMainInfosContainer}>
                  <TextField
                    error={!isValidGivenName}
                    id="outlined-givenname"
                    label={t('first_name')}
                    onChange={(event) => setModifiedGivenName(event.target.value)}
                    value={givenName}
                  />

                  <TextField
                    error={!isValidFamilyName}
                    id="outlined-familyname"
                    label={t('name')}
                    margin="normal"
                    onChange={(event) => setModifiedFamilyName(event.target.value)}
                    value={familyName}
                  />
                </div>
                <div className={classes.userMainInfosContainer}>
                  <TextField
                    defaultValue={me.email}
                    disabled
                    id="outlined-email"
                    label="Email"
                    margin="normal"
                  />
                  <TextField
                    id="outlined-email"
                    label={t('job_role')}
                    margin="normal"
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </div>
                <div className={classes.vipAccessCodeInputContainer}>
                  <Typography className={classes.vipAccessCodeTitle}>{t('vip_code')}</Typography>
                  <CodeInput
                    autoFocus={false}
                    className={classes.vipAccessCodeInput}
                    fields={6}
                    inputMode="verbatim"
                    inputStyle={{
                      border: `1px solid ${invalidCode ? 'red' : 'rgba(0, 0, 0, 0.23)'}`,
                      borderRadius: '10px',
                      color: '#666',
                      fontSize: '1.5em',
                      height: '40px',
                      margin: '0 4.25px',
                      outline: '0',
                      textAlign: 'center',
                      textTransform: 'uppercase',
                      width: '40px',
                    }}
                    name="vip-access-code-input"
                    onChange={(value) => {
                      setAccessCode(value.toUpperCase());
                      setInvalidCode(false);
                    }}
                    type="text"
                    value={accessCode}
                  />
                </div>
              </CardContent>
            </Card>
            <AcceptTerms checked={acceptedTerms} handleChecked={setAcceptedTerms} />
            <Fab
              className={classes.continueButton}
              color="primary"
              disabled={!continueButtonDisabled}
              onClick={() => {
                setIsLoading(true);
                updateProfile(
                  { accessCode, profile: modifiedProfile },
                  {
                    onError: () => {
                      setInvalidCode(true);
                    },
                    onSettled: () => setIsLoading(false),
                  },
                );
              }}
              variant="extended"
            >
              {t('create_account')}
              {isLoading ? (
                <CircularProgress className={classes.continueButtonProgress} size={25} />
              ) : null}
            </Fab>
          </>
        )}
      </Media>
    </WelcomeView>
  );
};
