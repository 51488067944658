import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { ApiQuery, useApiRouteMaker } from '../useApi';

/**
 * Hook used for updating the profile of the user
 */
export const useUpdateProfile = (): UseMutationResult<
  unknown,
  unknown,
  Params$UseUpdateProfile,
  unknown
> => {
  const makeRoute = useApiRouteMaker(ApiQuery.POST_MY_PROFILE);

  const queryClient = useQueryClient();

  const updateProfileMutation = useMutation<unknown, unknown, Params$UseUpdateProfile>(
    async ({ accessCode, profile }) => {
      const route = makeRoute();
      await axios({
        data: { accessCode, me: profile },
        method: route.method,
        url: route.url,
        withCredentials: true,
      });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(ApiQuery.GET_MY_PROFILE);
      },
    },
  );

  return updateProfileMutation;
};

interface Params$UseUpdateProfile {
  accessCode?: string;
  profile?: {
    familyName?: string;
    givenName?: string;
    position?: string;
  };
}
