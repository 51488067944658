import React from 'react';
import { useMailPreview } from '../../../hooks';
import { MailSkeleton } from '../../DocumentSkeleton/MailSkeleton';
import { NoPreviewFoundBackground } from '../../DocumentsListBackgrounds/NoPreviewFoundBackground';
import { SanitizedHtmlTypography } from '../../SanitizedHtmlTypography';
import { useStyles } from '../styles';

export const MailPreview: React.FC<MailPreviewProps> = ({ accessLink, documentId }) => {
  const classes = useStyles();

  const { data: fullDocument, isLoading } = useMailPreview(documentId);

  if (isLoading) return <MailSkeleton />;

  return (
    <div className={classes.previewInnerContainer}>
      {fullDocument ? (
        <SanitizedHtmlTypography html={fullDocument.htmlContent} />
      ) : (
        <NoPreviewFoundBackground accessLink={accessLink} />
      )}
    </div>
  );
};

interface MailPreviewProps {
  accessLink?: string;
  documentId: string;
}
