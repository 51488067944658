import { makeStyles } from '../../material';

export const useStyles = makeStyles(
  {
    checkBoxesContainer: {
      marginTop: '10px',
    },
    connectorHeader: {
      alignItems: 'center',
      display: 'flex',
    },
    connectorLogo: {
      maxHeight: '100%',
      maxWidth: '100%',
    },
    connectorLogoContainer: {
      alignItems: 'center',
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      marginLeft: '18px',
      width: 50,
    },
    connectorNameInput: {
      marginLeft: '20px',
      width: '100%',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 80px)',
    },
    dialogSubtitle: {
      display: 'flex',
      marginBottom: '10px',
      marginTop: '20px',
    },
    errorMessage: {
      color: '#e74c3c',
      fontSize: '12px',
      left: '0',
      position: 'absolute',
    },
    oneClickDialog: {
      height: '85vh',
      maxHeight: '650px',
      padding: '20px',
      width: '450px',
    },
    selectRootsContainer: {
      border: '1px solid #ccc',
      borderRadius: '5px',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      minHeight: '240px',
      overflow: 'auto',
    },
    singleDialog: {
      maxHeight: '850px',
      padding: '20px',
      width: '550px',
    },
    startSyncButton: {
      marginTop: '20px',
    },
    startSyncButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      width: '100%',
    },
    startSyncButtonLoader: {
      margin: 'auto',
      position: 'absolute',
    },
    stepper: {
      padding: '0px',
      paddingBottom: '20px',
    },
    stepperStepLabel: {
      marginTop: '6px !important', // We are using `!important` here as there seems to be a bug in the order of the CSS injection
    },
  },
  { index: 1 },
);
