import * as queryString from 'query-string';
import React from 'react';
import useRouter from 'use-react-router';
import { useSlackRedirectUrl, useTranslations } from '../../hooks';

export const RedirectView: React.FC = () => {
  const { t } = useTranslations();

  const { location } = useRouter();

  const search = queryString.parse(location.search);
  const { channelId, messageId } = search;

  const { data: redirectUrl } = useSlackRedirectUrl({
    channelId: channelId as string,
    messageId: messageId as string,
  });

  if (redirectUrl) {
    document.location.href = redirectUrl;
  }

  return <p>{t('redirection_helper')}</p>;
};
