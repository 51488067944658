import { LabelInterface } from '@outmind/types';
import React, { memo, useCallback, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import {
  getRandomLabelColor as getRandomColor,
  Label,
  useAddLabel,
  useCreateLabel,
  useDocumentLabels,
  useRemoveLabel,
} from '../../hooks';
import { Actions, useDispatch, useSelector } from '../../store';
import { AddLabelButton } from './AddLabelButton';
import { LabelChipList } from './LabelChipList';
import { ShowMoreLabelsMenu } from './ShowMoreMenu';
import { useStyles } from './styles';

const DocumentLabelsNP: React.FC<DocumentLabelsProps> = React.memo(({ documentId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data: documentLabels = [] } = useDocumentLabels(documentId);

  const innerTagContainerRef = useRef<HTMLDivElement | null>(null);

  const onClickLabel = useCallback(
    (label: Label): void => {
      dispatch(Actions.addLabelFilter(label));
    },
    [dispatch],
  );

  const { mutateAsync: addLabel } = useAddLabel();

  const { mutateAsync: removeLabel } = useRemoveLabel();

  const { mutateAsync: createLabel } = useCreateLabel();

  const isPreviewOpened = useSelector((s) => s.previews.isOpened);

  return (
    <div
      ref={innerTagContainerRef}
      className={classes.tagsContainer}
      style={isPreviewOpened ? { display: 'none' } : { width: 'auto' }}
    >
      {documentLabels.length <= 3 ? (
        <LabelChipList
          labels={documentLabels}
          onClickLabel={onClickLabel}
          onDeleteLabel={(label) => removeLabel({ documentId, labelId: label.id })}
        />
      ) : (
        <ShowMoreLabelsMenu
          labels={documentLabels}
          onClickLabel={onClickLabel}
          onRemoveLabel={(label) => removeLabel({ documentId, labelId: label.id })}
        />
      )}
      <AddLabelButton
        addLabel={(label) => addLabel({ documentId, labelId: label.id })}
        createNewLabel={async (labelName) => {
          const labelId = uuid();
          await createLabel({
            label: {
              color: getRandomColor(),
              id: labelId,
              name: labelName,
            },
          });
          addLabel({ documentId, labelId });
          dispatch(Actions.notifyLabelCreated());
        }}
        documentLabels={documentLabels}
      />
    </div>
  );
});

interface DocumentLabelsProps {
  documentId: string;
  labels: LabelInterface[];
}

export const DocumentLabels = memo(DocumentLabelsNP);
