import { makeStyles } from '../../material';

const signinHelperBasic = {
  color: '#5C5C5C',
  fontSize: '24px',
  fontWeight: 500,
  marginBottom: 12,
  textAlign: 'center' as const,
};

const signinTitleBasic = {
  color: '#5C5C5C',
  fontSize: '32px',
  fontWeight: 600,
  textAlign: 'center' as const,
};

export const useStyles = makeStyles(
  {
    acceptTerms: {
      alignItems: 'center',
      display: 'flex',
      marginTop: 10,
    },
    acceptTermsHelper: {
      fontSize: '0.75rem',
    },
    chevronButton: { color: '#245EE5' },
    continueButton: {
      backgroundColor: '#245EE5',
      borderRadius: '10px',
      boxShadow: 'none',
      marginTop: 30,
      width: 200,
    },
    continueButtonProgress: {
      left: '50%',
      marginLeft: '-12px',
      marginTop: '-12px',
      position: 'absolute',
      top: '50%',
    },
    imageLogoContainer: {
      '& > img': { display: 'block', marginLeft: '20px', width: '80px' },
      left: '12px',
      position: 'absolute',
      top: '12px',
    },
    signinHelper: signinHelperBasic,
    signinHelperMobile: {
      ...signinHelperBasic,
      fontSize: '1rem',
    },
    signinTitle: signinTitleBasic,
    signinTitleMobile: {
      ...signinTitleBasic,
      fontSize: '1.5rem',
    },
    userAvatarContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    userInfosCard: {
      minWidth: '450px',
      padding: 10,
    },
    userInfosEmailInput: {
      width: '390px',
    },
    userInfosFamilyNameInput: {
      margin: '8px',
      maxWidth: '150px',
    },
    userMainInfosContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    userSubInfosContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    vipAccessCodeInput: {
      margin: '8px -4px 0 -4px',
    },
    vipAccessCodeInputContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '24px',
    },
    vipAccessCodeTitle: {
      color: '#666',
    },
  },
  { index: 1 },
);
