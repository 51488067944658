import React, { useState } from 'react';
import { useRuntimeEnv } from '../../hooks';
import { NavBarMenu } from '../NavBarMenu';
import { SpotlightShortcutConfigDialog } from '../SpotlightShortcutConfigDialog';
import { useStyles } from './styles';

/**
 * UserProfile displays the right part of the AppBar with the notifications and user avatar
 */
export const NavBarActions: React.FC = () => {
  const classes = useStyles();

  const { inElectron } = useRuntimeEnv();

  const [openSpotlightShortcutConfigDialog, setOpenSpotlightShortcutConfigDialog] = useState(false);

  const openSpotlightShortcutConfigModal = (): void => {
    setOpenSpotlightShortcutConfigDialog(true);
  };

  return (
    <div className={classes.userProfileContainer}>
      {inElectron ? (
        <SpotlightShortcutConfigDialog
          close={() => setOpenSpotlightShortcutConfigDialog(false)}
          open={openSpotlightShortcutConfigDialog}
        />
      ) : null}
      <NavBarMenu openSpotlightShortcutConfigModal={openSpotlightShortcutConfigModal} />
    </div>
  );
};
