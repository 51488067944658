import { ExtendedSources, sources } from '@outmind/types';
import React from 'react';

import { useGetAuthUrl, useLinkHandler, useRuntimeEnv, useTranslations } from '../../../../hooks';
import { Button, Typography } from '../../../../material';
import { SourceIcon } from './SourceIcon';
import { useStyles } from './styles';

const sourceNames = {
  ...sources,
  gsuite: { name: 'Suite Google' },
  o365: { name: 'Microsoft 365' },
};

/**
 * Renders the source icon and the button that redirect to the source to be authenticated
 */
export const PreconfiguredSource: React.FC<PreconfiguredSourceProps> = ({ source, disabled }) => {
  const classes = useStyles();
  const { t } = useTranslations();

  const { data: authUrl } = useGetAuthUrl(source);
  const { inElectron } = useRuntimeEnv();
  const onClick = useLinkHandler(authUrl, { targetBlank: inElectron });

  return (
    <div className={classes.preconfiguredSourceContainer}>
      <div className={classes.preconfiguredSourceNameContainer}>
        <Typography className={classes.sourceName} variant="subtitle1">
          {sourceNames[source].name}
        </Typography>
        <div className={classes.sourceIconContainer}>
          <SourceIcon source={source} />
        </div>
      </div>
      <div>
        <Button
          classes={{
            contained: classes.containedButton,
            disabled: disabled ? classes.containedButtonDisabled : undefined,
          }}
          color="primary"
          disabled={disabled}
          onClick={onClick}
          variant="contained"
        >
          {disabled ? t('added') : t('add')}
        </Button>
      </div>
    </div>
  );
};

interface PreconfiguredSourceProps {
  disabled?: boolean;
  source: ExtendedSources;
}
