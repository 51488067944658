import { ElasticPerson } from '@outmind/types';
import React from 'react';
import { Avatar, AvatarProps } from '../../material';

export const PersonAvatar: React.FC<PersonAvatarProps> = ({ person, ...props }) => {
  const { avatarUrl, familyName, givenName } = person;

  let initials = '';
  if (givenName) {
    initials = familyName ? `${givenName[0]}${familyName[0]}` : givenName[0];
  } else {
    initials = '@';
  }

  if (avatarUrl) return <Avatar {...props} src={avatarUrl} />;

  return <Avatar {...props}>{initials}</Avatar>;
};

interface PersonAvatarProps extends AvatarProps {
  person: ElasticPerson;
}
