import React from 'react';
import { Avatar, Skeleton } from '../../../material';
import { useStyles } from './styles';

/**
 * Renders the Message Threads content message when its inner data is still loading
 */
export const MessageSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.messageHeader}>
        <Skeleton>
          <Avatar className={classes.singleMessageAvatar} variant="rounded" />
        </Skeleton>
        <div className={classes.messageHeaderContent}>
          <Skeleton variant="text" width={Math.random() * 150 + 30} />
          <div className={classes.messageThreadDate}>
            <Skeleton height={15} variant="text" width={80} />
          </div>
        </div>
      </div>
      <div className={classes.messageContent}>
        <Skeleton height={17} variant="text" width={Math.random() * 250 + 20} />
      </div>
    </div>
  );
};
