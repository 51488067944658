import React, { useState } from 'react';
import { useTranslations } from '../../hooks';
import { DoneIcon, EditIcon, TextField, Tooltip, Typography } from '../../material';
import { useStyles } from './styles';

export const EditableTextField: React.FC<EditableTextFieldProps> = ({
  disableEdit,
  text,
  setText,
}) => {
  const classes = useStyles();
  const { t } = useTranslations();
  const [editMode, setEditMode] = useState(false);
  const [internalText, setInternalText] = useState(text);

  if (editMode) {
    return (
      <div className={classes.textFieldContainer}>
        <TextField
          className={classes.textFieldInput}
          onChange={(e) => setInternalText(e.target.value)}
          value={internalText}
        />
        <Tooltip placement="right" title={t('save')}>
          <DoneIcon
            className={classes.iconButton}
            onClick={() => {
              setText(internalText);
              setEditMode(false);
            }}
          />
        </Tooltip>
      </div>
    );
  }

  return (
    <div className={classes.textFieldContainer}>
      <Typography className={classes.textField} noWrap title={internalText}>
        {internalText}
      </Typography>
      {!disableEdit ? (
        <Tooltip placement="right" title={t('rename')}>
          <EditIcon className={classes.iconButton} onClick={() => setEditMode(true)} />
        </Tooltip>
      ) : null}
    </div>
  );
};

interface EditableTextFieldProps {
  disableEdit?: boolean;
  setText: (text: string) => void;
  text: string;
}
