import { DocumentIndiceNames, ElasticFile } from '@outmind/types';
import React, { memo, useEffect, useState } from 'react';

import { useSearch, useTranslations } from '../../../hooks';
import { Typography } from '../../../material';
import { useStyles } from '../styles';
import { DuplicateFileList } from './DuplicateFileList';

/**
 * Displays the list of duplicate files
 */
const DuplicateFilesNoMemo: React.FC<DuplicateFilesProps> = ({
  clusterId,
  documentId,
  documentName,
  similar,
}) => {
  const classes = useStyles();

  const { t } = useTranslations();

  const [duplicateFiles, setDuplicateFiles] = useState(similar.documents);
  const [maxDisplayedElements, setMaxDisplayedElements] = useState(2);

  const { results: files = [], fetchNextPage } = useSearch(DocumentIndiceNames.Files, {
    clusterId,
    enabled: false,
  });

  useEffect(() => {
    if (files?.length) {
      const newRetrievedFiles = [
        ...duplicateFiles,
        ...files
          .filter(
            (file) =>
              ![documentId, ...duplicateFiles.map((doc) => doc.id)].includes(file.document.id),
          )
          .map((_file) => _file.document),
      ];
      if (newRetrievedFiles.length !== duplicateFiles.length) {
        setDuplicateFiles(newRetrievedFiles as ElasticFile[]);
      }
    }
  }, [files]);

  const showMoreFiles = (): void => {
    fetchNextPage();
    setMaxDisplayedElements((_maxDisplayedElements) => _maxDisplayedElements + 10);
  };

  const seeMoreButton = (
    <Typography className={classes.seeMoreButton} onClick={showMoreFiles}>
      <span>{t('see_more', { documentName })}</span>
    </Typography>
  );

  return (
    <>
      <Typography className={classes.similarResultsTitle}>{t('similar_results')}</Typography>
      <DuplicateFileList
        duplicateFiles={duplicateFiles}
        maxDisplayedElements={maxDisplayedElements}
      />
      <div>{similar.total > maxDisplayedElements ? seeMoreButton : null}</div>
    </>
  );
};

export const DuplicateFiles = memo(DuplicateFilesNoMemo);

interface DuplicateFilesProps {
  clusterId: string;
  documentId: string;
  documentName: string;
  similar: {
    documents: ElasticFile[];
    total: number;
  };
}
