import {
  DocumentIndiceNames,
  DocumentIndicesExtended,
  NonIndexedDocumentNames,
} from '@outmind/types';

export const indexOrder: DocumentIndicesExtended[] = [
  '*',
  DocumentIndiceNames.Files,
  DocumentIndiceNames.Pages,
  DocumentIndiceNames.Mails,
  DocumentIndiceNames.Messages,
  DocumentIndiceNames.Cards,
  DocumentIndiceNames.ConstructionTools,
  DocumentIndiceNames.ConstructionSites,
  DocumentIndiceNames.People,
  DocumentIndiceNames.Missions,
  NonIndexedDocumentNames.Contacts,
  NonIndexedDocumentNames.Opportunities,
  NonIndexedDocumentNames.Accounts,
];
