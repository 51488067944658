import { makeStyles } from '../../material';

const signinHelperBasic = {
  color: '#757575',
  fontSize: '1.125rem',
  marginBottom: 20,
  marginTop: 30,
  textAlign: 'center' as const,
};
const signupHelperBasic = {
  color: '#757575',
  fontSize: '1rem',
  marginTop: 30,
  textAlign: 'center' as const,
};
const signinTitleBasic = {
  color: '#757575',
  fontSize: '2rem',
  textAlign: 'center' as const,
};

export const useStyles = makeStyles(
  {
    imageLogoContainer: {
      '&>img': { width: '280px' },
      marginBottom: '45px',
      marginTop: '32px',
    },
    signinHelper: signinHelperBasic,
    signinHelperMobile: {
      ...signinHelperBasic,
      fontSize: '1rem',
    },
    signinTitle: signinTitleBasic,
    signinTitleMobile: {
      ...signinTitleBasic,
      fontSize: '1.5rem',
    },
    signupHelper: signupHelperBasic,
    signupHelperMobile: {
      ...signupHelperBasic,
      fontSize: '0.875rem',
    },
  },
  { index: 1 },
);
