import * as queryString from 'query-string';
import React, { useCallback } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import useRouter from 'use-react-router';
import { useLoggedIn } from '../../hooks';

// Environment variables
const { REACT_APP_NO_AUTH } = process.env;

/**
 * Used to route components that should not be displayed if user is loggedin
 * If user is loggedIn he will be redirected to the redirectRoute
 */
export const RedirectRoute: React.FC<RedirectRouteProps> = ({ component: Component, ...rest }) => {
  const isLoggedIn = useLoggedIn();

  const { location } = useRouter();

  const parsedQuery = queryString.parse(location.search);
  const redirectTo = parsedQuery['next'] ? decodeURIComponent(parsedQuery['next'] as string) : '/';

  const renderDemoRoute = useCallback(() => <Redirect to={redirectTo} />, [redirectTo]);

  const renderRedirectRoute = useCallback(
    (props) => (isLoggedIn ? <Redirect to={redirectTo} /> : <Component {...props} />),
    [Component, isLoggedIn, redirectTo],
  );

  if (REACT_APP_NO_AUTH === 'true') {
    // When auth is disabled, isSignedIn is always true
    // We always redirect the user to the redirectTo path
    return <Route {...rest} render={renderDemoRoute} />;
  }

  return <Route {...rest} render={renderRedirectRoute} />;
};

interface RedirectRouteProps extends RouteProps {
  component: React.ComponentClass | React.FC;
}
