import React, { useCallback, useState } from 'react';
import { GoogleLogin, GoogleLoginResponse } from 'react-google-login';

import GoogleLogo from '../../assets/auth/glogo.svg';
import { useRuntimeEnv } from '../../hooks';
import { ButtonBase, CircularProgress } from '../../material';
import { useStyles } from './styles';

// Environment variables
const { REACT_APP_WEBAPP_URL: WEBAPP_URL } = process.env;

export const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({
  authCallback,
  buttonText,
  clientId,
  variant,
}) => {
  const classes = useStyles({ variant });

  const [isLoading, setIsLoading] = useState(false);

  const { inElectron, inExtension } = useRuntimeEnv();

  const loginStart = useCallback(() => {
    if (inElectron) {
      window.electron?.openWithDefaultHandler(
        `${WEBAPP_URL}/signin/start?flow=deep&provider=google`,
      );
    } else if (inExtension) {
      window.open(`${WEBAPP_URL}/signin/start?flow=extension&provider=google`);
      window.addEventListener('message', (e) => {
        if (e.data === 'will-close') window.document.location.reload();
      });
    }
  }, [inExtension, inElectron]);

  if (inElectron || inExtension) {
    return (
      <div className={classes.loginButtonContainer}>
        <ButtonBase className={classes.loginButtonRaw} onClick={loginStart}>
          <img alt="" className={classes.logo} height="18" src={GoogleLogo} width="18" />
          {buttonText}
        </ButtonBase>
      </div>
    );
  }

  return (
    <div className={classes.loginButtonContainer}>
      <GoogleLogin
        buttonText={buttonText}
        className={classes.loginButton}
        clientId={clientId}
        cookiePolicy="single_host_origin"
        disabled={isLoading}
        onFailure={(err) => {
          authCallback?.(err);
          setIsLoading(false);
        }}
        onRequest={() => setIsLoading(true)}
        onSuccess={(res) => authCallback?.(undefined, (res as GoogleLoginResponse).tokenId)}
      />
      {isLoading ? (
        <div className={classes.progressContainer}>
          <CircularProgress size={variant === 'large' ? 24 : 20} />
        </div>
      ) : null}
    </div>
  );
};

interface GoogleLoginButtonProps {
  authCallback: (err?: Error, idToken?: string) => void;
  buttonText: string;
  clientId: string;
  variant?: 'medium' | 'large';
}
