/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = (props: LabelTahStyleProps) =>
  makeStyles(
    {
      chipRoot: {
        backgroundColor: `${props.color}25`,
        margin: '4px 12px 4px 0px',
        paddingLeft: '5px',
        paddingRight: '4px',
      },
      chipRootFilter: {
        backgroundColor: `${props.color}25`,
        paddingLeft: '5px',
        paddingRight: '4px',
      },
      colorPrimary: {
        borderColor: 'transparent',
        color: props.color,
        fontSize: '14px',
      },
      deleteIcon: {
        '&:hover': {
          color: '#5C5C5C',
        },
        color: props.color,
        marginTop: '1px',
      },
      iconSmall: {
        color: props.color,
        marginRight: '-2px',
      },
    },
    { index: 1 },
  )(props);

interface LabelTahStyleProps {
  color: string;
}
