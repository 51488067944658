import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      navigationButtonLink: {
        '&:hover': { backgroundColor: theme.designSystem.colors.active.main, color: 'white' },
        border: `2px solid ${theme.designSystem.colors.active.main}`,
        borderRadius: '12px',
        color: theme.designSystem.colors.active.main,
        padding: '8px',
        textDecoration: 'none',
      },
    },
    { index: 1 },
  )();
};
