import { SigninProvider } from '@outmind/types';
import * as queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import useRouter from 'use-react-router';
import { useSignIn } from '../../hooks';
import { StartUpLoader } from '../StartUpLoader';

/**
 * View that signs the user in using the `tokenId` passed as URL parameter in this route
 */
export const SignInCallbackView: React.FC = () => {
  const [flowType, setFlowType] = useState<string>();
  const [provider, setProvider] = useState<SigninProvider>();
  const [tokenId, setTokenId] = useState<string>();

  const { history, location } = useRouter();

  const { mutate: signIn } = useSignIn();

  useEffect(() => {
    const {
      flow: _flowType,
      tokenId: _tokenId,
      provider: _provider,
    } = queryString.parse(location.search);
    if (_tokenId && typeof _tokenId === 'string') setTokenId(_tokenId);
    if (_provider && typeof _provider === 'string') setProvider(_provider as SigninProvider);
    if (_flowType && typeof _flowType === 'string') setFlowType(_flowType);
  }, [location]);

  useEffect(() => {
    if (tokenId && provider) {
      signIn(
        { flowType, idToken: tokenId, provider },
        {
          onSettled: () => history.push('/'),
        },
      );
    }
  }, [flowType, history, provider, signIn, tokenId]);

  return <StartUpLoader />;
};
