import React from 'react';
import { OutmindLogoSvg } from '../OutmindLogoSvg';
import { useStyles } from './styles';

export const StartUpLoader: React.FC<StartUpLoaderProps> = ({ inSpotlight }) => {
  const classes = useStyles({ inSpotlight });

  return (
    <div className={classes.startupLoaderContainer}>
      <div className={classes.loaderCircle} />
      <div className={classes.loaderLineMask}>
        <div className={classes.loaderLine} />
      </div>
      <div className={classes.outmindLogoContainer}>
        <OutmindLogoSvg className={classes.outmindLogoSvg} />
      </div>
    </div>
  );
};

interface StartUpLoaderProps {
  inSpotlight?: boolean;
}
