/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () =>
  makeStyles(
    {
      closeDialogButton: {
        color: '#8A8C8F',
        position: 'absolute',
        right: 0,
        top: 0,
      },
      container: {
        padding: '12px 12px 0px 12px',
      },
      descriptionSubtitle: { color: '#8A8C8F', fontSize: '14px', marginBottom: '4px' },
      descriptionTitle: { color: '#545E6A', fontSize: '16px', fontWeight: 600 },
      dialogContent: {
        display: 'flex',
      },
      elementsContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
      },
      faqLink: { color: '#245EE5', fontWeight: 600 },
      firstColumnContainer: {
        marginBottom: '42px',
        maxHeight: '450px',
        overflow: 'auto',
        padding: '42px 42px 0px 42px',
      },
      helperIcon: { '& > img': { width: '60px' }, padding: '12px 24px 12px 0px' },
      helperTextContainer: { alignItems: 'center', display: 'flex' },
      iconButton: {
        color: '#FAD271',
        padding: '4px',
      },
      maxWidth: { maxWidth: '860px' },
      preconfiguredSourceContainer: { alignItems: 'center', display: 'flex' },
      reconnectButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '16px',
      },
      secondColumnContainer: {
        background: '#F3F7FF',
        padding: '42px',
      },
      welcomeText: {
        color: '#245EE5',
        fontSize: '24px',
        fontWeight: 600,
        marginBottom: '20px',
      },
    },
    { index: 1 },
  )();
