/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () =>
  makeStyles(
    {
      chipRoot: {
        breakInside: 'avoid',
        margin: '4px 8px 4px 0px',
        maxWidth: 450,
      },
      colorPrimary: {
        '&:hover': {
          backgroundColor: '#6C6C6C !important',
        },
        backgroundColor: '#C4C4C4 !important',
        color: 'white',
        fontWeight: 600,
      },
      iconSmall: {
        marginLeft: '4px !important',
        marginRight: '-8px',
        width: '16px',
      },
    },
    { index: 1 },
  )();
