import { SigninProvider } from '@outmind/types';
import React from 'react';
import Media from 'react-media';
import { useTranslations } from '../../hooks';
import { GoogleLoginButton } from '../GoogleLoginButton';
import { MicrosoftLoginButton } from '../MicrosoftLoginButton';
import { useStyles } from './styles';

// Environment variables
const { REACT_APP_GAPI_CLIENT_ID, REACT_APP_MICROSOFT_CLIENT_ID } = process.env;

export const SignInButtons: React.FC<SignInButtonsProps> = ({ onSuccess, variant }) => {
  const { t } = useTranslations();

  const classes = useStyles();

  return (
    <Media query="(max-width: 959px)">
      {(matches) => (
        <>
          <div className={!matches ? classes.buttonWrapper : classes.buttonWrapperMobile}>
            <GoogleLoginButton
              authCallback={(_, token) => onSuccess?.('google', token as string)}
              buttonText={t('continue_with_google')}
              clientId={REACT_APP_GAPI_CLIENT_ID!}
              variant={variant}
            />
          </div>
          <div className={!matches ? classes.buttonWrapper : classes.buttonWrapperMobile}>
            <MicrosoftLoginButton
              authCallback={(_, token) => onSuccess?.('microsoft', token as string)}
              buttonText={t('continue_with_microsoft')}
              clientId={REACT_APP_MICROSOFT_CLIENT_ID!}
              variant={variant}
            />
          </div>
        </>
      )}
    </Media>
  );
};

interface SignInButtonsProps {
  onSuccess?: (provider: SigninProvider, token: string) => void;
  variant?: 'medium' | 'large';
}
