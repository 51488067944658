import { Typography } from '@material-ui/core';
import { ChevronRight } from '@styled-icons/fluentui-system-filled/ChevronRight';
import clsn from 'classnames';
import React from 'react';

import { useStyles } from './styles';
/**
 * LabeledBox display a rounded box labeled with a title
 */
export const LabeledBox: React.FC<LabeledBoxProps> = ({
  children,
  classes,
  icon,
  title,
  seeAll,
}) => {
  const defaultClasses = useStyles();

  const boxContainerClassName = clsn(classes?.boxContainer, defaultClasses.boxContainer);
  const borderBoxClassName = clsn(classes?.borderBox, defaultClasses.borderBox);
  const boxTitleClassName = clsn(classes?.boxTitle, defaultClasses.boxTitle);

  return (
    <div className={boxContainerClassName}>
      <div className={borderBoxClassName}>
        <span className={boxTitleClassName}>
          <Typography className={defaultClasses.seeAllButton} onClick={seeAll}>
            {icon}
            {title}
            <ChevronRight className="seeAllButtonIcon" size={14} style={{ marginLeft: '6px' }} />
          </Typography>
        </span>
        {children}
      </div>
    </div>
  );
};

interface LabeledBoxProps {
  children: React.ReactNode;
  classes?: {
    borderBox?: string;
    boxContainer?: string;
    boxTitle?: string;
  };
  icon?: JSX.Element;
  seeAll?: () => void;
  title?: string;
}
