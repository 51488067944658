import React from 'react';
import { useTranslations } from '../../../hooks';
import { Chip, PeopleAltIcon } from '../../../material';
import { useStyles } from './styles';

export const SharedConnectorChip: React.FC<SharedConnectorChipProps> = ({
  connectorOwner,
  connectorOwnerId,
  userId,
}) => {
  const classes = useStyles();
  const { t } = useTranslations();

  const sharedByText = `${t('shared_by')} ${connectorOwner}`;
  const sharedToText = t('shared_to_your_team');

  return (
    <Chip
      classes={{
        colorPrimary: classes.colorPrimary,
        root: classes.chipRoot,
      }}
      color="primary"
      icon={<PeopleAltIcon />}
      label={connectorOwnerId === userId ? sharedToText : sharedByText}
      size="small"
      variant="outlined"
    />
  );
};

interface SharedConnectorChipProps {
  connectorOwner: string;
  connectorOwnerId: string;
  userId?: string;
}
