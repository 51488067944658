import React, { useEffect, useRef } from 'react';

import { useConnectors, useRuntimeEnv, useUserProfile } from '../../hooks';
import { Dialog } from '../../material';
import { AuthenticationProvider } from '../AuthenticationProvider';
import { Router } from '../Router';
import { SearchBar } from '../SearchBar';
import { OpenInOutmindButton } from './OpenInOutmindButton';
import { SpotlightBody } from './SpotlightBody';
import { useStyles } from './styles';

/**
 * View rendered behind the `/spotlight` route. This view is supposed to only be
 * used by the @outmind/spotlight-extension package but can be accessed for development
 * purposes at the `/spotlight` route
 */
export const SpotlightView: React.FC = () => {
  const classes = useStyles();

  const { isLoading: loadingUser } = useUserProfile();
  const { isLoading: loadingConnectors, isSuccess } = useConnectors();
  const shouldShowStartUpLoader = loadingUser || loadingConnectors;
  const isLoggedIn = loadingConnectors || isSuccess;

  const searchBarRef = useRef<HTMLInputElement>(null);

  const { inElectron } = useRuntimeEnv();

  useEffect(() => {
    if (inElectron) {
      window.electron?.onFocusSearchBar(() => {
        searchBarRef.current?.select();
        searchBarRef.current?.focus();
      });
    }
  }, [inElectron]);

  return (
    <AuthenticationProvider>
      <Router>
        <Dialog
          BackdropProps={{ style: { backgroundColor: 'unset' } }}
          classes={{ paperScrollPaper: classes.spotlightContainer }}
          onClose={() => window.electron?.closeSpotlightView()}
          open
          PaperProps={{
            elevation: 0,
          }}
        >
          <SearchBar ref={searchBarRef} autoFocus fullWidth hasBigHeight inSpotlight isSharp />
          <div className={classes.spotlightResultsContainer}>
            <SpotlightBody
              isLoggedIn={isLoggedIn}
              shouldShowStartUpLoader={shouldShowStartUpLoader}
            />
          </div>
          {isLoggedIn && !shouldShowStartUpLoader && <OpenInOutmindButton />}
        </Dialog>
      </Router>
    </AuthenticationProvider>
  );
};
