/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () =>
  makeStyles({
    container: {
      alignItems: 'center',
      display: 'flex',
    },
    icon: {
      color: 'white',
      fontSize: '0.875rem',
      margin: 'auto',
      width: 'auto',
    },
    iconContainer: {
      alignItems: 'center',
      backgroundColor: '#C4C4C4',
      borderRadius: '15px',
      display: 'flex',
      height: '22px',
      justifyContent: 'center',
      width: '22px',
    },
    label: {
      color: '#888888',
      overflow: 'hidden',
      paddingLeft: '5px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  })();
