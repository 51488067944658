import React, { useState } from 'react';
import { useTranslations } from '../../../hooks';
import { AddIcon, IconButton, Tooltip } from '../../../material';
import { AddSourceDialog } from '../AddSourceDialog';
import { useStyles } from './styles';

export const AddSourceDialogButtonSecondary: React.FC = () => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const { t } = useTranslations();

  return (
    <div className={classes.addSourceButtonContainer}>
      <Tooltip aria-label="Add" title={t('add_source')}>
        <IconButton
          aria-label="Add"
          className={classes.addSourceButton}
          onClick={() => setOpen(true)}
        >
          <AddIcon className={classes.addSourceButtonIcon} />
        </IconButton>
      </Tooltip>
      <AddSourceDialog close={() => setOpen(false)} open={open} />
    </div>
  );
};
