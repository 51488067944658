import { Source, sources } from '@outmind/types';
import { useTranslations } from '../../hooks';
import { TranslationKey } from '../../locales/types';

export const usePopper = (source: Source): string => {
  const { t } = useTranslations();
  return t('sync_discovered_popper', {
    elements: t(`${source.toLowerCase()}_elements` as TranslationKey),
    items: t(`${source.toLowerCase()}_items` as TranslationKey),
    newItems: t(`${source.toLowerCase()}_new_items` as TranslationKey),
    source: sources[source].name,
  });
};
