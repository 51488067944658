import React from 'react';
import { User as Icon } from 'tabler-icons-react';
import { SanitizedHtmlTypography } from '../../SanitizedHtmlTypography';
import { useStyles } from './styles';

export const PersonLabel: React.FC<PersonLabelProps> = ({ person: { familyName, givenName } }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <Icon className={classes.icon} size="14" />
      </div>
      <SanitizedHtmlTypography
        className={classes.label}
        html={`${givenName} ${familyName}`}
        variant="body2"
      />
    </div>
  );
};

interface PersonLabelProps {
  person: {
    familyName: string;
    givenName: string;
  };
}
