import { Source } from '@outmind/types';
import React, { Fragment } from 'react';

import { GuardedRoute } from '../GuardedRoute';
import { AuthCallbackView } from './AuthCallbackView';
import { AuthStartView } from './AuthStartView';

export const SourcesRouter: React.FC = () => {
  const routes = Object.values(Source).map((source) => (
    <Fragment key={source}>
      <GuardedRoute
        component={() => <AuthCallbackView source={source} />}
        path={`/sources/${source}/auth-callback`}
      />
      <GuardedRoute
        component={() => <AuthStartView source={source} />}
        path={`/sources/${source}/auth-start`}
      />
    </Fragment>
  ));

  return (
    <>
      {routes}
      <GuardedRoute
        component={() => <AuthCallbackView source="o365" />}
        path="/sources/o365/auth-callback"
      />
      <GuardedRoute
        component={() => <AuthStartView source="o365" />}
        path="/sources/o365/auth-start"
      />
      <GuardedRoute
        component={() => <AuthCallbackView source="gsuite" />}
        path="/sources/gsuite/auth-callback"
      />
      <GuardedRoute
        component={() => <AuthStartView source="gsuite" />}
        path="/sources/gsuite/auth-start"
      />
    </>
  );
};
