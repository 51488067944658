/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () =>
  makeStyles(
    {
      closeMenuButton: {
        color: 'rgba(0, 0, 0, 0.54)',
        position: 'absolute',
        right: 0,
        top: 0,
      },
      container: {
        padding: '16px',
      },
      hiddenLabelCount: {
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
        alignItems: 'center',
        border: '1px solid #efefef',
        borderRadius: '16px',
        cursor: 'pointer',
        display: 'flex',
        height: '25px',
        padding: '3px 5px 3px 5px',
      },
      hiddenLabelText: {
        color: '#2b90e9',
        fontWeight: 'bold',
        padding: '4px',
      },
      hiddenLabelTooltip: {
        marginRight: '12px',
      },
      iconButtonContainer: {
        alignItems: 'center',
        display: 'flex',
        marginLeft: '4px',
      },
      itemIcon: {
        minWidth: '36px',
      },
      labelTagsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: '450px',
      },
      showMoreMenuHeader: {
        color: '#333',
        fontSize: '0.8rem',
        padding: '12px 0',
      },
    },
    { index: 1 },
  )();
