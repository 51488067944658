import * as microsoftTeams from '@microsoft/teams-js';
import { SigninProvider } from '@outmind/types';
import React, { useEffect, useState } from 'react';
import Media from 'react-media';

import outmindLogo from '../../assets/outmind-logo.svg';
import { useRuntimeEnv, useSignIn, useTranslations } from '../../hooks';
import { SignInButtons, WelcomeView } from '..';
import { useStyles } from './styles';

export const SignInClassicView: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslations();

  const [idToken, setIdToken] = useState<string>();
  const [provider, setProvider] = useState<SigninProvider>();

  const { inMicrosoftTeams } = useRuntimeEnv();

  const { mutate: signIn } = useSignIn();

  if (inMicrosoftTeams) {
    microsoftTeams.authentication.getAuthToken({
      failureCallback: (error) => {
        console.log(`Could not login in teams: ${error}`);
      },
      successCallback: (result) => {
        setIdToken(result);
        setProvider('teams-sso');
      },
    });
  }

  useEffect(() => {
    if (idToken && provider) signIn({ idToken, provider });
  }, [idToken, provider, signIn]);

  return (
    <WelcomeView withFooter>
      <Media query="(max-width: 959px)">
        {(matches) => (
          <>
            <div className={!matches ? classes.signinTitle : classes.signinTitleMobile}>
              {t('welcome_to')}
            </div>
            <div className={classes.imageLogoContainer}>
              <img alt="outmind-logo" src={outmindLogo} />
            </div>
            <div className={!matches ? classes.signinHelper : classes.signinHelperMobile}>
              {t('signin_helper')}
            </div>
            <SignInButtons
              onSuccess={(_provider, token) => {
                setProvider(_provider);
                setIdToken(token);
              }}
              variant="large"
            />
          </>
        )}
      </Media>
    </WelcomeView>
  );
};
