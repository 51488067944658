import { sources } from '@outmind/types';

import { ActionTypes } from '../actions';
import { AppActions } from '../types';
import { NotificationsState } from './types';
import { makeErrorNotification, makeInfoNotification, makeSuccessNotification } from './utils';

export const reducer = (
  state: NotificationsState = initialState,
  action: AppActions,
): NotificationsState => {
  switch (action.type) {
    case ActionTypes.NOTIFY_CONNECTOR_CREATION_FAILED: {
      return makeErrorNotification('connector_creation_failed');
    }

    case ActionTypes.NOTIFY_CONNECTOR_DELETION_STARTED:
      return makeInfoNotification('deletion_started');

    case ActionTypes.NOTIFY_CONNECTOR_DELETION_SUCCESS: {
      const source = action.payload;
      return makeSuccessNotification('source_deletion_success', {
        sourceName: sources[source].name,
      });
    }

    case ActionTypes.NOTIFY_LABEL_CREATED: {
      return makeInfoNotification('new_tag_created');
    }

    case ActionTypes.NOTIFY_CREDENTIALS_UPDATE_FAILED: {
      const source = action.payload;
      return makeErrorNotification('credentials_update_failed', {
        source: sources[source].name,
      });
    }

    case ActionTypes.NOTIFY_CREDENTIALS_UPDATE_SUCCESS: {
      const source = action.payload;
      return makeSuccessNotification('credentials_update_success', {
        source: sources[source].name,
      });
    }

    case ActionTypes.NOTIFY_LABEL_DELETED: {
      return {
        ...state,
        message: 'tag_deleted',
        type: 'info',
      };
    }

    case ActionTypes.NOTIFY_LABEL_UPDATED: {
      return {
        ...state,
        message: 'tag_updated',
        type: 'info',
      };
    }

    case ActionTypes.NOTIFY_SEARCH_REFRESHED: {
      return makeInfoNotification('new_data_search_refreshed');
    }

    case ActionTypes.NOTIFY_SOURCE_SHARED: {
      return makeSuccessNotification('source_shared_success');
    }

    case ActionTypes.NOTIFY_START_SYNC_FAILED: {
      return makeErrorNotification('start_sync_failed');
    }

    case ActionTypes.NOTIFY_UNAUTHORIZED_ACCESS: {
      return makeErrorNotification('unauthorized_access');
    }

    default:
      return state;
  }
};

export const initialState: NotificationsState = {};
