import './whyDidYouRender';
import './init-monitoring';

import { App, AppProvider } from '@outmind/ui-elements';
import { render } from 'react-dom';

import { unregister } from './serviceWorker';

render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById('root') as HTMLElement,
);

// Unregister serviceWorker in case an old is still running
unregister();
