import { LocalSources, Source } from '@outmind/types';
import React, { useEffect, useState } from 'react';
import useRouter from 'use-react-router';

import { useOrganisationSources, useStartSync } from '../../hooks';
import { Grid } from '../../material';
import { Actions, useDispatch, useSelector } from '../../store';
import { AddSourceDialog } from '../AddSource';
import { PreconfiguredSourcesDialog } from '../AddSource/PreconfiguredSourcesDialog';
import { DisplayOnComputerOnly } from '../DisplayOnComputerOnly';
import { DownloadAppDialog } from '../Document/DownloadAppDialog';
import { DocumentsList } from '../DocumentsList';
import { getSkipDownloadDesktopView } from '../DownloadDesktopAppView';
import { FileNotFoundDialog } from '../FileNotFoundDialog';
import { Filters } from '../Filters';
import { Intercom } from '../Intercom';
import { AppBar } from '../NavBar';
import {
  ConfigureLocalConnectorDialog,
  ConfigureSourceDialog,
  ConfigureSourcesDialog,
} from '../SourcesConfigurationDialog';
import { useStyles } from './styles';

export const MainViewBase: React.FC<MainViewBaseProps> = ({
  actionSource,
  classes,
  connectorId,
  goToDownloadDesktop,
  onboardingViewIsOpen,
}) => {
  const dispatch = useDispatch();

  const [preconfiguredSourcesViewForceClosed, setPreconfiguredSourcesViewForceClosed] =
    useState(false);

  const [syncComponents, setSyncComponents] = useState<JSX.Element[]>([]);
  const isDesktopAppDialogOpen = useSelector((state) => state.desktopAppDialog.isOpened);
  const isFileNotFoundDialogOpen = useSelector(
    (state) => state.desktopAppDialog.fileNotFoundIsOpened,
  );
  const shouldShowPreconfiguredSourcesDialog = useSelector(
    (state) => state.preconfiguredSources.shouldShowPreconfiguredSourcesDialog,
  );

  const {
    data: organisationSources = { connectedSources: [], sourcesToBeConnected: [] },
    isLoading,
  } = useOrganisationSources();

  const [onboardingViewForceClosed, setForceClosed] = useState(
    !!organisationSources.sourcesToBeConnected.length || isLoading,
  );

  const { mutate: startSync } = useStartSync();

  const { history } = useRouter();

  useEffect(() => {
    if (typeof actionSource === 'string') {
      if (actionSource === 'o365' || actionSource === 'gsuite') {
        const connectorIds = (connectorId as string).split(',').map((sourceId) => {
          const [source, id] = sourceId.split(':');
          return {
            id,
            source: source as Source,
          };
        });
        setSyncComponents([<ConfigureSourcesDialog connectors={connectorIds} />]);
      } else if (actionSource === Source.LocalDisk || actionSource === Source.NetworkDisk) {
        setSyncComponents([
          <ConfigureLocalConnectorDialog source={actionSource as LocalSources} />,
        ]);
      } else if (actionSource === Source.Notion) {
        startSync({
          connectorId: connectorId as string,
          source: actionSource as Source,
        });
        history.push('/');
      } else {
        const connector = {
          id: connectorId as string,
          source: actionSource as Source,
        };
        setSyncComponents([<ConfigureSourceDialog connector={connector} />]);
      }
    } else {
      setSyncComponents([]);
    }
  }, [actionSource, connectorId, history, startSync]);

  const goToDownloadDesktopView = !getSkipDownloadDesktopView();
  if (goToDownloadDesktopView) {
    goToDownloadDesktop();
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <AppBar withSearchBar />
      </Grid>
      <DisplayOnComputerOnly>
        <Grid className={classes.leftColumnContainer} item lg={2} md={3}>
          <div className={classes.leftColumnContainerInner}>
            <Filters />
          </div>
        </Grid>
      </DisplayOnComputerOnly>
      <Grid className={classes.mainColumnContainer} item lg={10} md={9}>
        <DocumentsList />
        <DownloadAppDialog
          close={() => dispatch(Actions.closeAppDialog())}
          openDialog={isDesktopAppDialogOpen}
        />
        <FileNotFoundDialog
          close={() => dispatch(Actions.closeFileNotFoundDialog())}
          openDialog={isFileNotFoundDialogOpen}
        />
      </Grid>
      <PreconfiguredSourcesDialog
        onClose={() => setPreconfiguredSourcesViewForceClosed(true)}
        open={
          !!organisationSources.sourcesToBeConnected.length &&
          !preconfiguredSourcesViewForceClosed &&
          (shouldShowPreconfiguredSourcesDialog || onboardingViewIsOpen)
        }
      />
      <AddSourceDialog
        close={() => setForceClosed(true)}
        onboardingMode
        open={
          onboardingViewIsOpen &&
          !onboardingViewForceClosed &&
          !organisationSources.sourcesToBeConnected.length
        }
      />
      <Intercom />
      {syncComponents}
    </Grid>
  );
};

/**
 * Describes the props of the `MainViewBase` component
 */
interface MainViewBaseProps {
  actionSource?: string;
  classes: ReturnType<typeof useStyles>;
  connectorId?: string;
  goToDownloadDesktop: () => void;
  onboardingViewIsOpen: boolean;
}
