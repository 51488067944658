import React from 'react';
import { Home as Icon } from 'tabler-icons-react';
import { SanitizedHtmlTypography } from '../../SanitizedHtmlTypography';
import { useStyles } from './styles';

export const AddressLabel: React.FC<AddressLabelProps> = ({
  address: { line1, line2, zipCode, city },
}) => {
  const classes = useStyles();

  const displayedAddress = (() =>
    `${line1 ? `${line1}, ` : ''}${line2 ? `${line2}, ` : ''}${zipCode}, ${city}`)();
  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <Icon className={classes.icon} size="14" />
      </div>
      <SanitizedHtmlTypography className={classes.label} html={displayedAddress} variant="body2" />
    </div>
  );
};

interface AddressLabelProps {
  address: {
    city: string;
    line1?: string;
    line2?: string;
    zipCode: string;
  };
}
