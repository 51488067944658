import { useQuery, UseQueryResult } from 'react-query';
import { ApiQuery } from '../useApi';
import { Label } from './label';
import { useLabels } from './useLabels';

/**
 * Retrieves the Labels of a Document
 */
export const useDocumentLabels = (documentId: string): UseQueryResult<Label[]> => {
  const getDocumentsLabelsQuery = useQuery<string[]>(
    [ApiQuery.GET_DOCUMENT_LABELS, documentId],
    async () => [],
    {
      staleTime: Infinity, // Never stale
    },
  );

  const { data: documentLabelIds = [] } = getDocumentsLabelsQuery;

  return useLabels(documentLabelIds);
};
