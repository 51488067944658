import { LocalSources } from '@outmind/types';
import React from 'react';

import { SeparatedPaths } from '../../..';
import { useTranslations } from '../../../hooks';
import { AddIcon, Button, List, ListItem, ListItemText } from '../../../material';
import { useStyles } from '../styles';
import { SelectLocalTreeView } from './SelectLocalTreeView';

export const SelectLocalRoots: React.FC<SelectLocalRootsProps> = ({
  folders,
  noRootToSync,
  removeFolder,
  selectFolders,
  separatedPaths,
  source,
}) => {
  const classes = useStyles();

  const { t } = useTranslations();

  const noRootToSyncHelper =
    noRootToSync && !folders.length ? (
      <ListItem>
        <ListItemText
          className={classes.lightNoRootToSyncHelperText}
          primary={t('no_syncable_folders')}
        />
      </ListItem>
    ) : null;

  const selectLocalTreeView = folders.length ? (
    <SelectLocalTreeView
      folders={folders}
      removeFolder={removeFolder}
      separatedPaths={separatedPaths}
      source={source}
    />
  ) : null;

  return (
    <div className={classes.localRootsElementsContainer}>
      <List className={classes.localRootsElements}>
        {noRootToSyncHelper}
        {selectLocalTreeView}
      </List>
      <div className={classes.addFoldersButtonContainer}>
        <Button
          className={classes.addFoldersButton}
          color="primary"
          onClick={() => selectFolders()}
          size="medium"
          startIcon={<AddIcon />}
        >
          {t('select_folders')}
        </Button>
      </div>
    </div>
  );
};

interface SelectLocalRootsProps {
  folders: string[];
  noRootToSync: boolean;
  removeFolder: (folder: string) => void;
  selectFolders: () => Promise<void>;
  separatedPaths: SeparatedPaths;
  source: LocalSources;
}
