import React from 'react';
import { OutmindLogoSvg } from '../OutmindLogoSvg';
import { useStyles } from './styles';

/**
 * Background component displays the blue background image in the App
 */
export const Background: React.FC = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.background}>
      {children}
      <div className={classes.wallpaper} />
      <OutmindLogoSvg className={classes.outmindLogo} />
    </div>
  );
};
