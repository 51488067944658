import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { useStyles } from './styles';

export const BaseNavigationButton: React.FC<BaseNavigationButtonProps> = ({ text, ...rest }) => {
  const classes = useStyles();

  return (
    <Link className={classes.navigationButtonLink} {...rest}>
      {text}
    </Link>
  );
};

interface BaseNavigationButtonProps extends LinkProps {
  text: string;
}
