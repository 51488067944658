import React from 'react';
import { Hash as Icon } from 'tabler-icons-react';
import { useTranslations } from '../../../hooks';
import { SanitizedHtmlTypography } from '../../SanitizedHtmlTypography';
import { useStyles } from './styles';

export const ToolSerialNumberLabel: React.FC<ToolSerialNumberLabelProps> = ({ serialNumber }) => {
  const classes = useStyles();
  const { t } = useTranslations();

  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <Icon className={classes.icon} size="14" />
      </div>
      <SanitizedHtmlTypography
        className={classes.label}
        html={`${t('serial_number')} : ${serialNumber}`}
        variant="body2"
      />
    </div>
  );
};

interface ToolSerialNumberLabelProps {
  serialNumber: string;
}
