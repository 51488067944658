import { SearchMessagesResult } from '@outmind/types';
import React, { useEffect, useState } from 'react';

import { useFeatureFlags, useLinkHandler } from '../../hooks';
import { Paper } from '../../material';
import { Actions, useDispatch, useSelector } from '../../store';
import { ConnectorLogos } from '../ConnectorLogos';
import { ContextMenu } from '../ContextMenu';
import { DocumentLabels } from '../DocumentLabels';
import { DocumentConversation } from './DocumentConversation';
import { DocumentDate } from './DocumentDate';
import { SearchResultMessage } from './SearchResultMessage';
import { useStyles } from './styles';

/**
 * Displays an `ElasticMessage` document
 */
export const Message: React.FC<MessageProps> = ({ result }) => {
  const { document } = result;
  const { labels } = document;

  const classes = useStyles();

  const { data: flags } = useFeatureFlags();

  const dispatch = useDispatch();

  const previewInfos = useSelector((state) => state.previews.infos);

  const [isSelected, setIsSelected] = useState(false);

  const [mouseCoordinates, setMouseCoordinates] = useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>({
    mouseX: null,
    mouseY: null,
  });

  const openPreview = (): void => {
    dispatch(Actions.openPreview());
    dispatch(Actions.selectMessagePreview(result));
  };

  const handleRightClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    if (!mouseCoordinates.mouseX && !mouseCoordinates.mouseY) {
      setMouseCoordinates({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
      setIsSelected(true);
    }
  };

  const handleMenuClose = (): void => {
    setIsSelected(false);
    setMouseCoordinates({
      mouseX: null,
      mouseY: null,
    });
  };

  const onclickHandler = (): (() => void) | undefined => {
    if (mouseCoordinates.mouseX && mouseCoordinates.mouseY) return;
    return openPreview;
  };

  const goToMessage = useLinkHandler(document.accessLink);

  useEffect(() => {
    setIsSelected(previewInfos?.document.id === document.id);
  }, [previewInfos?.document.id, document.id]);

  const connectorLogo = ConnectorLogos[document.source];
  return (
    <Paper
      className={!isSelected ? classes.documentContainer : classes.documentContainerClicked}
      elevation={0}
      onClick={onclickHandler()}
      onContextMenu={handleRightClick}
    >
      <div className={classes.messageHeader}>
        <div className={classes.messageConnectorLogo}>
          <img alt="" className={classes.connectorLogo} src={connectorLogo} />
        </div>
        <div
          className={classes.messageHeaderContent}
          style={{ maxWidth: flags?.withDocumentLabels.enabled ? 'calc(100% - 200px)' : undefined }}
        >
          {document.to ? <DocumentConversation name={document.to} /> : null}
          <div className={classes.messageHeaderDate}>
            <DocumentDate date={document.date} />
          </div>
        </div>
        {labels && flags?.withDocumentLabels.enabled ? (
          <DocumentLabels documentId={document.id} labels={labels} />
        ) : null}
      </div>
      <div>
        <SearchResultMessage result={result} />
      </div>
      {mouseCoordinates.mouseX && mouseCoordinates.mouseY ? (
        <ContextMenu
          documentId={document.id}
          mouseX={mouseCoordinates.mouseX}
          mouseY={mouseCoordinates.mouseY}
          onClose={handleMenuClose}
          onOpen={goToMessage}
          onPreview={openPreview}
        />
      ) : null}
    </Paper>
  );
};

interface MessageProps {
  result: SearchMessagesResult;
}
