import { ExtendedSources } from '@outmind/types';
import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { useTranslations } from '../../../hooks';
import { Button, TextField } from '../../../material';
import { ConnectorLogos } from '../../ConnectorLogos';
import { useStyles } from './styles';

export const AddSourceCredentialsDialog: React.FC<AddSourceCredentialsDialogProps> = ({
  source,
}) => {
  const classes = useStyles();

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const { t } = useTranslations();

  return !shouldRedirect ? (
    <div className={classes.credentialsDialog}>
      <div className={classes.sourceLogoBigContainer}>
        <img alt="" className={classes.sourceLogo} src={ConnectorLogos[source]} />
      </div>
      <TextField
        className={classes.credentialsDialogInput}
        id="login-field"
        label={t('login_name')}
        onChange={(e) => setLogin(e.currentTarget.value)}
        variant="outlined"
      />
      <TextField
        className={classes.credentialsDialogInput}
        id="password-field"
        label={t('password')}
        onChange={(e) => setPassword(e.currentTarget.value)}
        type="password"
        variant="outlined"
      />
      <Button
        className={classes.credentialsDialogButton}
        color="primary"
        onClick={() => setShouldRedirect(true)}
        variant="contained"
      >
        {t('login_verb')}
      </Button>
    </div>
  ) : (
    <Redirect to={`/sources/${source}/auth-callback?flow=web&code=${login}:${password}`} />
  );
};

interface AddSourceCredentialsDialogProps {
  source: ExtendedSources;
}
