import React from 'react';
import moment from 'moment';
import { CalendarEvent as Icon } from 'tabler-icons-react';
import { useTranslations } from '../../../hooks';
import { Typography } from '../../../material';
import { useStyles } from './styles';

export const DateLabel: React.FC<DateLabelProps> = ({ date }) => {
  const classes = useStyles();
  const { t } = useTranslations();

  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <Icon className={classes.icon} size="14" />
      </div>
      <Typography className={classes.label} variant="body2">
        {`${t('next_checkup_date')} - ${date.format('DD/MM/YYYYY')}`}
      </Typography>
    </div>
  );
};

interface DateLabelProps {
  date: moment.Moment;
}
