import React from 'react';
import { useTranslations } from '../../hooks';
import { Checkbox } from '../../material';
import { SanitizedHtmlTypography } from '../SanitizedHtmlTypography';
import { useStyles } from './styles';

export const AcceptTerms: React.FC<AcceptTermsProps> = ({ checked, handleChecked }) => {
  const classes = useStyles();

  const { t } = useTranslations();

  const termsOfServiceLink = `<a href="https://www.outmind.fr/cgu">${t('terms_of_service')}</a>`;
  const privacyPolicyLink = `<a href="https://www.outmind.fr/data-privacy">${t(
    'privacy_policy',
  )}</a>`;

  const acceptTermsHelper = t('accept_terms_helper', { privacyPolicyLink, termsOfServiceLink });

  return (
    <div className={classes.acceptTerms}>
      <Checkbox
        checked={checked}
        color="primary"
        onChange={(event) => handleChecked(event.target.checked)}
      />
      <SanitizedHtmlTypography className={classes.acceptTermsHelper} html={acceptTermsHelper} />
    </div>
  );
};

interface AcceptTermsProps {
  checked: boolean;
  handleChecked: (checked: boolean) => void;
}
