import React from 'react';
import { labelColors, useTranslations } from '../../../../hooks';
import { DialogContentText, FormControl } from '../../../../material';
import { useStyles } from '../styles';
import { ColorBubble } from './ColorBubble';

export const LabelColorSelector: React.FC<LabelColorSelectorProps> = ({
  colors = labelColors,
  selectedColor,
  setSelectedColor,
}) => {
  const classes = useStyles();
  const { t } = useTranslations();

  const colorElements = colors.map((color) => (
    <ColorBubble
      key={color}
      color={color}
      selectedColor={selectedColor}
      setSelectedColor={(selectedColorValue) => setSelectedColor(selectedColorValue)}
    />
  ));
  return (
    <div className={classes.labelColorSelectorContainer}>
      <FormControl className={classes.formControl}>
        <DialogContentText>{t('choose_color')}</DialogContentText>
        <div className={classes.colorBubblesContainer}>{colorElements}</div>
      </FormControl>
    </div>
  );
};

interface LabelColorSelectorProps {
  colors: string[];
  selectedColor: string;
  setSelectedColor: (color: string) => void;
}
