import * as queryString from 'query-string';
import useReactRouter from 'use-react-router';

import { FlowType } from '../../useFlowType';

/**
 * Parses the state contained in the "/auth-callback" URL to provide the `AuthCallbackState`
 */
export const useAuthCallbackState = (): AuthCallbackState => {
  const { location } = useReactRouter();

  const cbState: AuthCallbackState = { shouldUpdateCredentials: false };

  // Parse the code from the search query string in the location
  const search = queryString.parse(location.search);
  const hash = queryString.parse(location.hash);
  const path = location.pathname.split('/');
  const state = search['state'] ? JSON.parse(search['state'] as string) : undefined;
  if (state?.shouldUpdate) {
    cbState.shouldUpdateCredentials = true;
  }

  cbState.connectorId = state?.connectorId;

  const queryCode = search['code'] ?? hash['token'] ?? hash['/token'];
  const queryFlow = search['flow'] ?? path[path.length - 1];
  if (typeof queryCode === 'string' && typeof queryFlow === 'string') {
    cbState.code = queryCode;
    cbState.flow = queryFlow as FlowType;
  }

  return cbState;
};

interface AuthCallbackState {
  code?: string;
  connectorId?: string;
  flow?: FlowType;
  shouldUpdateCredentials: boolean;
}
