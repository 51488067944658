import clsn from 'classnames';
import React from 'react';

import { useTranslations } from '../../../hooks';
import {
  ClearIcon,
  FolderIcon,
  IconButton,
  ListItem,
  Tooltip,
  Typography,
  WarningRoundedIcon,
} from '../../../material';
import { useStyles } from '../styles';

export const SelectLocalTreeItem: React.FC<SelectLocalTreeItemProps> = ({
  folder,
  outOfScopeNetworkDisk,
  outOfScopeLocalDisk,
  removeFolder,
}) => {
  const classes = useStyles();
  const { t } = useTranslations();
  const pathSeparator = new RegExp(/[\\\/]/);

  const translatedRoot = (): string => {
    const roots = folder.split(pathSeparator);
    const root = roots[roots.length - 1];
    if (roots.length === 4) {
      if (root === 'Desktop') return t('desktop');
      if (root === 'Downloads') {
        return t('downloads');
      }
    }
    return root;
  };

  return (
    <ListItem className={classes.localItemList}>
      <div className={classes.listItemLabelRoot}>
        <FolderIcon className={classes.listLocalItemLabelIcon} />
        <Typography
          className={clsn(classes.treeItemLabelText, {
            [classes.alert]: outOfScopeNetworkDisk || outOfScopeLocalDisk,
          })}
        >
          {translatedRoot()}
        </Typography>
        <div className={classes.spacer} />
        {outOfScopeLocalDisk || outOfScopeNetworkDisk ? (
          <Tooltip
            arrow
            placement="right"
            title={
              outOfScopeLocalDisk ? t('localdisk_out_of_scope') : t('networkdisk_out_of_scope')
            }
          >
            <WarningRoundedIcon classes={{ root: classes.alert }} />
          </Tooltip>
        ) : null}
        <IconButton onClick={() => removeFolder(folder)}>
          <ClearIcon className={classes.clearIcon} />
        </IconButton>
      </div>
    </ListItem>
  );
};

interface SelectLocalTreeItemProps {
  folder: string;
  outOfScopeLocalDisk: boolean;
  outOfScopeNetworkDisk: boolean;
  removeFolder: (folder: string) => void;
}
