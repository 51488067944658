/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      borderBox: {
        display: 'block',
        paddingBottom: '24px',
        position: 'relative',
        width: '100%',
      },
      boxContainer: {
        display: 'flex',
      },
      boxTitle: {
        display: 'flex',
        paddingBottom: '8px',
        paddingLeft: '24px',
      },
      seeAllButton: {
        '&:hover': {
          '& .seeAllButtonIcon': {
            transform: 'translateX(4px)',
            transition: 'transform 0.2s ease',
          },
          cursor: 'pointer',
        },
        color: theme.designSystem.colors.secondary.main,
        fontSize: '14px',
        fontWeight: 700,
      },
    },
    { index: 1 },
  )();
};
