import React from 'react';
import { useRuntimeEnv, useTranslations } from '../../../hooks';
import { Button, GoToIcon } from '../../../material';
import { useSelector } from '../../../store';
import { useStyles } from './styles';

const { REACT_APP_WEBAPP_URL: WEBAPP_URL } = process.env;

export const OpenInOutmindButton: React.FC = () => {
  const classes = useStyles();
  const q = useSelector((s) => s.search.q);
  const { inElectron } = useRuntimeEnv();
  const { t } = useTranslations();

  const openOutmindApp = (): void => {
    const encodedSearchQuery = q ? `?query=${encodeURIComponent(q)}` : '/';
    if (inElectron) {
      window.electron!.openDeepLink(encodedSearchQuery);
    } else {
      window.open(`${WEBAPP_URL}${encodedSearchQuery}`);
    }
  };

  return (
    <div className={classes.openInOutmindButtonContainer}>
      <Button
        classes={{ label: classes.openInOutmindButtonLabel }}
        className={classes.openInOutmindButton}
        color="primary"
        endIcon={<GoToIcon />}
        onClick={() => openOutmindApp()}
        variant="text"
      >
        {t('open_in_outmind_app')}
      </Button>
    </div>
  );
};
