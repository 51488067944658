import React from 'react';
import { Button } from '../../material';
import { useStyles } from './styles';

export const ActionButton: React.FC<ActionButtonProps> = ({ action, helperText }) => {
  const classes = useStyles();

  return (
    <Button className={classes.actionButton} color="primary" onClick={action} variant="contained">
      {helperText}
    </Button>
  );
};

interface ActionButtonProps {
  action: () => void;
  helperText: string;
}
