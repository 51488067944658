import React from 'react';
import { useTranslations } from '../../../hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '../../../material';

export const ConfirmActionDialog: React.FC<ConfirmActionDialogProps> = ({
  dialogTitle,
  confirmButtonText,
  onClose,
  onActionConfirmed,
  textHelper,
  open,
}) => {
  const { t } = useTranslations();

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={() => onClose()}
      open={open}
    >
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{textHelper}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus color="default" onClick={() => onClose()}>
          {t('cancel')}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            onClose();
            onActionConfirmed();
          }}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface ConfirmActionDialogProps {
  confirmButtonText: string;
  dialogTitle: string;
  onActionConfirmed: () => void;
  onClose: () => void;
  open: boolean;
  textHelper: string;
}
