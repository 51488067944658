import React from 'react';
import { Skeleton } from '../../../material';

/**
 * Renders the Mail Thread content message when its inner data is still loading
 */
export const MailContentSkeleton: React.FC = () => (
  <div>
    <Skeleton height={400} variant="rect" width="100%" />
  </div>
);
