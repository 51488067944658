import React from 'react';
import { Skeleton } from '../../../material';
import { useStyles } from './styles';

/**
 * Renders the Mail Thread Header when its inner data is still loading
 */
export const MailHeaderSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.messageThreadHeader}>
      <div className={classes.messageThreadLogo}>
        <Skeleton height={30} variant="circle" width={30} />
      </div>
      <div className={classes.messageThreadHeaderContent}>
        <Skeleton variant="text" width={250} />
      </div>
    </div>
  );
};
