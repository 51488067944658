import React from 'react';
import { useTranslations } from '../../hooks';
import { Button, ButtonGroup, EmailIcon, PhoneIcon, Typography } from '../../material';
import { useStyles } from './styles';

export const ContactPersonButtons: React.FC<ContactPersonProps> = ({
  alwaysEmail,
  alwaysPhone,
  email,
  phoneNumber,
}) => {
  const classes = useStyles();

  const { t } = useTranslations();

  if (!email && !alwaysEmail && !phoneNumber && !alwaysPhone) {
    return null;
  }

  const contactEmailButton =
    alwaysEmail || email ? (
      <Button
        className={classes.contactPersonButton}
        color="primary"
        disabled={!email}
        href={`mailto:${email}`}
        variant="outlined"
      >
        <EmailIcon className={classes.contactButtonIcon} />
        <Typography className={classes.contactButtonText}>
          {email ? 'Email' : t('no_email')}
        </Typography>
      </Button>
    ) : null;

  const contactPhoneButton =
    alwaysPhone || phoneNumber ? (
      <Button
        className={classes.contactPersonButton}
        color="primary"
        disabled={!phoneNumber}
        href={`tel:${phoneNumber}`}
        variant="outlined"
      >
        <PhoneIcon className={classes.contactButtonIcon} />
        <Typography className={classes.contactButtonText}>
          {phoneNumber || t('no_phone')}
        </Typography>
      </Button>
    ) : null;

  return (
    <ButtonGroup className={classes.contactPersonButtons}>
      {contactEmailButton}
      {contactPhoneButton}
    </ButtonGroup>
  );
};

interface ContactPersonProps {
  alwaysEmail?: boolean;
  alwaysPhone?: boolean;
  email?: string;
  phoneNumber?: string;
}
