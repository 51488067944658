import {
  DocumentType,
  SearchAccountsResult,
  SearchCardsResult,
  SearchConstructionSitesResult,
  SearchConstructionToolsResult,
  SearchContactsResult,
  SearchFilesResult,
  SearchMailsResult,
  SearchMessagesResult,
  SearchMissionsResult,
  SearchOpportunitiesResult,
  SearchPagesResult,
  SearchPeopleResult,
  SearchPrestationsResult,
  SearchResult,
} from '@outmind/types';
import React from 'react';

import { Account } from './Account';
import { Card } from './Card';
import { ConstructionSite } from './ConstructionSite';
import { ConstructionTool } from './ConstructionTool';
import { Contact } from './Contact';
import { File } from './File';
import { Mail } from './Mail';
import { Message } from './Message';
import { Mission } from './Mission';
import { MissionPrestation } from './MissionPrestation';
import { Opportunity } from './Opportunity';
import { Page } from './Page';
import { Person } from './Person';

/**
 * Displays an ElasticDoc that can come from a search request or getting last elements
 */
export const Document: React.FC<DocumentProps> = ({
  bookmarkView,
  result,
  fromSearchPerson,
  index,
}) => {
  const { document } = result;

  if (document.type === DocumentType.Account) {
    const accountResult = result as SearchAccountsResult;
    return <Account result={accountResult} />;
  }
  if (document.type === DocumentType.Card) {
    const cardResult = result as SearchCardsResult;
    return <Card result={cardResult} />;
  }
  if (document.type === DocumentType.ConstructionSite) {
    const constructionSiteResult = result as SearchConstructionSitesResult;
    return <ConstructionSite result={constructionSiteResult} />;
  }
  if (document.type === DocumentType.ConstructionTool) {
    const constructionToolResult = result as SearchConstructionToolsResult;
    return <ConstructionTool result={constructionToolResult} />;
  }
  if (document.type === DocumentType.Contact) {
    const contactResult = result as SearchContactsResult;
    return <Contact result={contactResult} />;
  }
  if (document.type === DocumentType.Mail) {
    const mailResult = result as SearchMailsResult;
    return <Mail index={index} result={mailResult} />;
  }
  if (document.type === DocumentType.Message) {
    const messageResult = result as SearchMessagesResult;
    return <Message result={messageResult} />;
  }
  if (document.type === DocumentType.Mission) {
    const missionResult = result as SearchMissionsResult;
    const prestationResult = result as SearchPrestationsResult;
    return fromSearchPerson ? (
      <MissionPrestation document={prestationResult.document} />
    ) : (
      <Mission result={missionResult} />
    );
  }
  if (document.type === DocumentType.Opportunity) {
    const opportunityResult = result as SearchOpportunitiesResult;
    return <Opportunity result={opportunityResult} />;
  }
  if (document.type === DocumentType.Person) {
    const personResult = result as SearchPeopleResult;
    return <Person result={personResult} />;
  }
  if (document.type === DocumentType.Page || document.type === DocumentType.Slide) {
    const pageResult = result as SearchPagesResult;
    return <Page index={index} result={pageResult} />;
  }
  const fileResult = result as SearchFilesResult;
  return (
    <File
      bookmarkView={bookmarkView}
      fromSearchPerson={fromSearchPerson}
      index={index}
      result={fileResult}
    />
  );
};

interface DocumentProps {
  bookmarkView?: boolean;
  fromSearchPerson?: boolean;
  index?: string;
  result: SearchResult;
}
