import { Connector, sources } from '@outmind/types';
import React from 'react';
import { useDeleteConnectors, useRuntimeEnv, useTranslations } from '../../hooks';
import { ConfirmDeleteDialog } from '../CustomDialog/ConfirmDeleteDialog';

export const DeleteConnector: React.FC<DeleteConnectorProps> = ({
  connector,
  onClose,
  showReallyDeleteDialog,
}) => {
  const { inElectron } = useRuntimeEnv();

  const { t } = useTranslations();

  const sourceName = connector.name;
  const sourceTypeName = sources[connector.source].name;

  const fullyDeleteConnector = (): void => {
    if (inElectron) window.electron?.killLocalDiskUpload(connector.id);

    deleteConnectors([connector]);
  };

  const { mutate: deleteConnectors } = useDeleteConnectors();

  return (
    <ConfirmDeleteDialog
      deleteButtonText={t('delete_source')}
      dialogTitle={t('really_delete_source', { sourceName, sourceTypeName })}
      onClose={onClose}
      onDeleteConfirmed={() => fullyDeleteConnector()}
      open={showReallyDeleteDialog}
      textHelper={t('delete_source_helper')}
    />
  );
};

interface DeleteConnectorProps {
  connector: Connector;
  onClose: () => void;
  onDelete?: () => void;
  showReallyDeleteDialog: boolean;
}
