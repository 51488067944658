import * as queryString from 'query-string';
import React, { useEffect } from 'react';
import useRouter from 'use-react-router';
import { CloseIcon, Dialog, DialogContent, DialogProps, IconButton } from '../../../material';
import { useStyles } from './styles';

export const AddSourceCustomDialog: React.FC<CustomDialogProps> = ({ children, onClose, open }) => {
  const classes = useStyles();
  const { location } = useRouter();
  const { action } = queryString.parse(location.search);

  useEffect(() => {
    if ((action as string) === 'sync:localdisk') onClose();
  }, [action, onClose]);

  const dialog =
    (action as string) !== 'sync:localdisk' ? (
      <Dialog
        classes={{ paper: classes.addSourceDialogPaper, paperWidthMd: classes.maxWidth }}
        maxWidth="md"
        onClose={onClose}
        open={open}
      >
        <DialogContent className={classes.addSourceDialogContent}>
          <IconButton
            aria-label="Close"
            className={classes.closeDialogButton}
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          {children}
        </DialogContent>
      </Dialog>
    ) : null;

  return dialog;
};

interface CustomDialogProps extends DialogProps {
  onClose: () => void;
  open: boolean;
}
