import React from 'react';
import { Skeleton } from '../../../material';
import { useStyles } from './styles';

/**
 * Renders the Message Threads Header when its inner data is still loading
 */
export const MessagesHeaderSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.messageThreadHeader}>
      <div className={classes.messageThreadLogo}>
        <Skeleton height={24} variant="circle" width={24} />
      </div>
      <div className={classes.messageThreadHeaderContent}>
        <Skeleton variant="text" width={250} />
        <div className={classes.messageThreadDate}>
          <Skeleton height={17} variant="text" width={80} />
        </div>
      </div>
    </div>
  );
};
