/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () =>
  makeStyles(
    {
      tagsContainer: {
        alignItems: 'center',
        display: 'grid',
        flexDirection: 'row',
        flexGrow: 1,
        gridTemplateColumns: 'repeat(auto-fit,  minmax(10px, max-content))',
        margin: '0 8px',
        overflow: 'hidden',
      },
    },
    { index: 1 },
  )();
