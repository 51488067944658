import { UserInterface } from '@outmind/types';
import React, { useCallback, useEffect } from 'react';
import { useUserProfile } from '../../hooks';
import { Actions, useDispatch, useSelector } from '../../store';

const INTERCOM_APP_ID = 'dkgpxi47';

/**
 * Intercom displays the Intercom support chat
 */
export const Intercom: React.FC = () => {
  const dispatch = useDispatch();

  const { data: user } = useUserProfile();

  const supportIsOpened = useSelector((s) => s.support.isOpened);

  const initIntercom = useCallback(
    (_user: UserInterface): void => {
      const name = `${_user.givenName} ${_user.familyName}`;

      if (!window.Intercom) {
        window.Intercom = (...args: unknown[]) => window.Intercom.q.push(args);
        window.Intercom.q = [];
        const s = document.createElement('script');
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${INTERCOM_APP_ID}`;
        document.head.appendChild(s);

        const userSettings = {
          email: _user.email,
          name,
          user_id: _user.id,
        };

        window.intercomSettings = {
          ...userSettings,
          app_id: INTERCOM_APP_ID,
          hide_default_launcher: true,
        };

        if (window.Intercom) {
          window.Intercom('boot', userSettings);
          window.Intercom('onHide', () => dispatch(Actions.closeSupport()));
        }
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (user) initIntercom(user);
  }, [initIntercom, user]);

  useEffect(() => {
    const canUseDOM = !!(
      typeof window !== 'undefined' &&
      window.document &&
      window.document.createElement
    );

    return () => {
      if (canUseDOM && window.Intercom) {
        window.Intercom('shutdown');
        delete window.Intercom;
        delete window.intercomSettings;
      }
    };
  }, []);

  useEffect(() => {
    if (supportIsOpened) window.Intercom('show');
    else window.Intercom('hide');
  }, [supportIsOpened]);

  return null;
};

declare const window: any;
