import { ExtendedSources, Source } from '@outmind/types';
import React from 'react';

import { useTranslations } from '../../../hooks';
import { ButtonBase, Card, CardContent, Grid, Typography } from '../../../material';
import { ConnectorLogos } from '../../ConnectorLogos';
import { useStyles } from './styles';

export const AddOffice365Button: React.FC<AddOfficeO365ButtonProps> = ({
  disabled,
  setChosenSource,
  setShowAddSourceConfirmationDialog,
}) => {
  const classes = useStyles();

  const { t } = useTranslations();

  return (
    <Grid item md={6} sm={12}>
      <Card className={classes.oneClickCard}>
        <ButtonBase
          classes={{ disabled: classes.sourceButtonDisabled }}
          className={classes.sourceButton}
          disabled={disabled}
          onClick={() => {
            setChosenSource('o365');
            setShowAddSourceConfirmationDialog(true);
          }}
        >
          <CardContent
            classes={{ root: classes.cardContentRoot }}
            className={classes.oneClickCardContent}
          >
            <div className={classes.oneClickIconsContainer}>
              <div className={classes.sourceMiniLogoContainer}>
                <img alt="" className={classes.sourceLogo} src={ConnectorLogos[Source.OneDrive]} />
              </div>
              <div className={classes.sourceMiniLogoContainer}>
                <img alt="" className={classes.sourceLogo} src={ConnectorLogos[Source.Outlook]} />
              </div>
              <div className={classes.sourceMiniLogoContainer}>
                <img
                  alt=""
                  className={classes.sourceLogo}
                  src={ConnectorLogos[Source.Sharepoint]}
                />
              </div>
              <div className={classes.sourceMiniLogoContainer}>
                <img alt="" className={classes.sourceLogo} src={ConnectorLogos[Source.Teams]} />
              </div>
            </div>
            <div className={classes.oneClickTextContainer}>
              <Typography className={classes.oneClickTextMain} variant="subtitle2">
                {t('cta_microsoft_365')}
              </Typography>
            </div>
          </CardContent>
        </ButtonBase>
      </Card>
    </Grid>
  );
};

interface AddOfficeO365ButtonProps {
  disabled?: boolean;
  setChosenSource: (source: ExtendedSources) => void;
  setShowAddSourceConfirmationDialog: (bool: boolean) => void;
}
