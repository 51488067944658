import { makeStyles } from '../../material';

export const useStyles = makeStyles(
  {
    addFoldersButton: {
      margin: '10px',
    },
    addFoldersButtonContainer: {
      backgroundColor: 'white',
      bottom: '0px',
      display: 'flex',
      height: '50px',
      justifyContent: 'center',
      width: '100%',
      zIndex: 0,
    },
    alert: {
      color: '#e74c3c !important',
    },
    clearIcon: {
      color: '#ccc !important',
      fontSize: '1.2rem',
    },
    lightNoRootToSyncHelperText: {
      color: '#ccc',
      display: 'flex',
      fontStyle: 'italic',
      justifyContent: 'center',
    },
    listItemLabelRoot: {
      alignItems: 'center',
      display: 'flex',
      padding: '7px',
      width: '100%',
    },
    listLocalItemLabelIcon: {
      color: '#78d0f9',
      fontSize: '2rem',
      marginRight: '10px',
    },
    localItemList: {
      paddingBottom: '3px',
      paddingTop: '3xp',
    },
    localRootsElements: {
      display: 'flex',
      flex: 1,
      maxHeight: '350px',
      overflow: 'auto',
      overflowX: 'hidden',
    },
    localRootsElementsContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    noRootToSyncHelperText: {
      color: '#444',
      fontStyle: 'italic',
    },
    rootCheckbox: {
      minWidth: '40px',
    },
    rootCheckboxIcon: {
      color: '#aaa',
      fontSize: '1rem',
      margin: '7px',
    },
    rootsCheckboxes: {
      height: '100%',
      padding: 0,
      width: '100%',
    },
    selectAllButton: {
      color: '#666',
      fontSize: '0.75rem',
      paddingLeft: '10px',
    },
    spacer: {
      flexGrow: 1,
    },
    treeItemContent: {},
    treeItemExpanded: {},
    treeItemGroup: {},
    treeItemIconContainer: {
      fontSize: '1.25rem',
      margin: '6px',
    },
    treeItemLabel: {},
    treeItemLabelIcon: {
      color: '#666',
      fontSize: '1.125rem',
      marginRight: '5px',
    },
    treeItemLabelRoot: {
      alignItems: 'center',
      display: 'flex',
      padding: '7px',
    },
    treeItemLabelText: {
      color: '#666',
      flexGrow: 1,
      paddingBottom: '2px',
    },
    treeItemRoot: {
      color: '#666',
    },
    treeItemSelected: {},
    treeView: {
      width: '100% !important',
    },
  },
  { index: 1 },
);
