import { apm } from '@elastic/apm-rum';
import * as microsoftTeams from '@microsoft/teams-js';
import * as Sentry from '@sentry/browser';
import React, { useEffect } from 'react';
import FullStory, { FullStoryAPI } from 'react-fullstory';

import { useFeatureFlags, useSetupApiBridge, useUserProfile } from '../../hooks';
import { Actions, useDispatch, useSelector } from '../../store';

/**
 * Triggers all the hooks related to authenticated calls
 */
export const AuthenticationProvider: React.FC = ({ children }) => {
  useSetupApiBridge();

  const { data: flags } = useFeatureFlags();

  const { inMicrosoftTeams } = useSelector((s) => s.user);

  const { data: me } = useUserProfile();

  const dispatch = useDispatch();

  useEffect(() => {
    if (me && flags?.withRUM.enabled) {
      const displayName = `${me.givenName} ${me.familyName}`;
      apm.setUserContext({
        email: me.email,
        id: me.id,
        username: displayName,
      });
      FullStoryAPI('identify', me.id, { displayName, email: me.email });
      Sentry.setUser({ email: me.email, id: me.id, username: displayName });
    }
  }, [me, flags?.withRUM.enabled]);

  useEffect(() => {
    if (!inMicrosoftTeams) {
      microsoftTeams.initialize(() => dispatch(Actions.microsoftTeamsDetect()));
    }
  }, [inMicrosoftTeams]);

  return (
    <>
      {me?.id && flags?.withRUM?.enabled ? <FullStory org="MKJDQ" /> : null}
      {children}
    </>
  );
};
