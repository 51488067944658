import React from 'react';
import { SanitizedHtmlTypography } from '../..';
import { BusinessIcon } from '../../material';
import { useStyles } from './styles';

/**
 * Displays the name of a business
 */
export const DocumentBusiness: React.FC<DocumentCreatorProps> = ({ name }) => {
  const classes = useStyles();

  return (
    <div className={classes.resultElementMeta}>
      <BusinessIcon className={classes.resultElementMetaIcon} />
      <SanitizedHtmlTypography className={classes.resultElementMetaText} html={name} />
    </div>
  );
};

interface DocumentCreatorProps {
  name: string;
}
