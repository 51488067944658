import classnames from 'classnames';
import React from 'react';
import { useStyles } from './styles';

export const ColorBubble: React.FC<ColorBubbleProps> = ({
  color,
  selectedColor,
  setSelectedColor,
}) => {
  const classes = useStyles({ color });

  const colorBubbleClasses = classnames(
    classes.colorBubble,
    selectedColor === color ? classes.isSelected : null,
  );
  return (
    <div className={classes.colorBubbleContainer}>
      <span
        aria-hidden="true"
        className={colorBubbleClasses}
        onClick={() => setSelectedColor(color)}
        onKeyDown={() => setSelectedColor(color)}
      />
    </div>
  );
};

interface ColorBubbleProps {
  color: string;
  selectedColor: string;
  setSelectedColor: (color: string) => void;
}
