import { ClientConnectorRoot } from '@outmind/types';
import React, { memo } from 'react';
import { ArrowDropDownIcon, ArrowRightIcon, TreeView } from '../../../material';
import { SelectRootsTreeItem } from './SelectRootsTreeItem';

const SelectRootsTreeViewNP: React.FC<SelectRootsTreeViewProps> = ({
  addRootsToRootsToSync,
  removeRootsFromRootsToSync,
  roots,
  selectedRootsIds,
}) => {
  const baseRootElements = roots.map((root) => (
    <SelectRootsTreeItem
      key={root.id}
      addRootsToRootsToSync={addRootsToRootsToSync}
      removeRootsFromRootsToSync={removeRootsFromRootsToSync}
      root={root}
      selectedRootsIds={selectedRootsIds}
    />
  ));

  return (
    <TreeView defaultCollapseIcon={<ArrowDropDownIcon />} defaultExpandIcon={<ArrowRightIcon />}>
      {baseRootElements}
    </TreeView>
  );
};

export const SelectRootsTreeView = memo(SelectRootsTreeViewNP);

interface SelectRootsTreeViewProps {
  addRootsToRootsToSync: (rootsToSync: Record<string, boolean>) => void;
  removeRootsFromRootsToSync: (rootsNotToSync: Record<string, boolean>) => void;
  roots: ClientConnectorRoot[];
  selectedRootsIds: Record<string, boolean>;
}
