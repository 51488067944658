import React from 'react';
import { Building as Icon } from 'tabler-icons-react';
import { SanitizedHtmlTypography } from '../../SanitizedHtmlTypography';
import { useStyles } from './styles';

export const AgencyLabel: React.FC<AgencyLabelProps> = ({ agency: { address, name } }) => {
  const classes = useStyles();

  const displayedString = (() => {
    const addressInfo = address?.city ? ` - ${address.city}` : '';
    return `${name}${addressInfo}`;
  })();
  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <Icon className={classes.icon} size="14" />
      </div>
      <SanitizedHtmlTypography className={classes.label} html={displayedString} variant="body2" />
    </div>
  );
};

interface AgencyLabelProps {
  agency: {
    address?: {
      city?: string;
      line1?: string;
      zipCode?: string;
    };
    code: string;
    name: string;
  };
}
