import React from 'react';
import { DashboardIcon } from '../../material';
import { SanitizedHtmlTypography } from '../SanitizedHtmlTypography';
import { useStyles } from './styles';

/**
 * Displays the parent board of the Trello element
 */
export const DocumentBoard: React.FC<DocumentBoardProps> = ({ boardName }) => {
  const classes = useStyles();

  return boardName ? (
    <div className={classes.resultElementMeta}>
      <DashboardIcon className={classes.resultElementMetaIcon} />
      <SanitizedHtmlTypography
        className={classes.resultElementMetaText}
        html={boardName}
        variant="body2"
      />
    </div>
  ) : null;
};

interface DocumentBoardProps {
  boardName?: string;
}
