import { makeStyles } from '../../material';

export const useStyles = makeStyles(
  {
    iconButton: {
      '&:hover': {
        color: '#333',
        cursor: 'pointer',
      },
      color: '#888',
      fontSize: '1rem',
      marginLeft: '5px',
    },
    textField: {
      maxWidth: '280px',
    },
    textFieldContainer: {
      alignItems: 'center',
      display: 'flex',
      height: '30px',
    },
    textFieldInput: {
      width: '280px',
    },
  },
  { index: 1 },
);
