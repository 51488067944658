import { DocumentIndiceNames, SearchConstructionSitesResult } from '@outmind/types';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useLinkHandler, useTranslations } from '../../hooks';
import { Paper, SearchIcon } from '../../material';
import { Actions, useDispatch } from '../../store';
import { ContextMenu } from '../ContextMenu';
import { ActionButton } from './ActionButton';
import { AddressLabel } from './AddressLabel';
import { AgencyLabel } from './AgencyLabel';
import { DocumentHeader } from './DocumentHeader';
import { PersonLabel } from './PersonLabel';
import { useStyles } from './styles';

/**
 * Displays an ElasticConstructionSite document
 */
export const ConstructionSite: React.FC<ConstructionSiteProps> = ({ result }) => {
  const { document, highlights } = result;

  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslations();

  const [isSelected, setIsSelected] = useState(false);

  const [mouseCoordinates, setMouseCoordinates] = useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>({
    mouseX: null,
    mouseY: null,
  });

  const handleRightClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    if (!mouseCoordinates.mouseX && !mouseCoordinates.mouseY) {
      setMouseCoordinates({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
      setIsSelected(true);
    }
  };

  const handleMenuClose = (): void => {
    setIsSelected(false);
    setMouseCoordinates({
      mouseX: null,
      mouseY: null,
    });
  };

  const onclickHandler = (): (() => void) | undefined => {
    if (mouseCoordinates.mouseX && mouseCoordinates.mouseY) return;
    return goToConstructionSite;
  };
  const goToConstructionSite = useLinkHandler(document.accessLink);

  const searchByConstructionSiteButton = (
    <div className={classes.actionButtonContainer}>
      <ActionButton
        icon={<SearchIcon fontSize="small" />}
        onClick={() => {
          dispatch(
            Actions.addDocumentRelationFilter({
              document: result.document,
              indexName: DocumentIndiceNames.ConstructionSites,
            }),
          );
          dispatch(Actions.setChosenIndexTab('*'));
        }}
        title={t('search_by_person')}
      />
    </div>
  );

  const displayedTitle = highlights?.title || document.title;
  const agency = {
    address: document.agency.address.city
      ? {
          city: highlights?.['agency.address.city'] ?? document.agency.address.city,
          line1: highlights?.['agency.address.line1'] ?? document.agency.address.line1,
          zipCode: highlights?.['agency.address.zipCode'] ?? document.agency.address.zipCode,
        }
      : undefined,
    code: highlights?.['agency.code'] ?? document.agency.code,
    name: highlights?.['agency.name'] ?? document.agency.name,
  };
  const address = {
    city: highlights?.['address.city'] ?? document.address.city,
    line1: highlights?.['address.line1'] ?? document.address.line1,
    line2: highlights?.['address.line2'] ?? document.address.line2,
    zipCode: highlights?.['address.zipCode'] ?? document.address.zipCode,
  };
  const accountManager = document.accountManager
    ? {
        familyName:
          highlights?.['accountManager.familyName'] ?? document.accountManager.familyName ?? '',
        givenName:
          highlights?.['accountManager.givenyName'] ?? document.accountManager.givenName ?? '',
      }
    : undefined;
  const tag = (() => {
    if (!document.finished && document.estimatedDuration && document.estimatedDuration > 0) {
      return {
        color: '#4CAF4F',
        id: uuid(),
        value: `${t('in_progress').toUpperCase()} - ${document.timeSpent ?? 0} / ${
          document.estimatedDuration
        } H (${Math.round(((document.timeSpent ?? 0) * 100) / document.estimatedDuration)}%)`,
      };
    }
    if (document.finished) {
      return {
        color: '#666666',
        id: uuid(),
        value: t('finished').toUpperCase(),
      };
    }
    return undefined;
  })();
  return (
    <Paper
      className={isSelected ? classes.documentContainerClicked : classes.documentContainer}
      elevation={0}
      onClick={onclickHandler()}
      onContextMenu={handleRightClick}
    >
      <DocumentHeader
        accessLink={document.accessLink}
        actionButtons={searchByConstructionSiteButton}
        documentId={document.id}
        labels={document.labels}
        source={document.source}
        tag={tag}
        title={displayedTitle}
      />
      <div className={classes.documentFooterContainer}>
        <AgencyLabel agency={agency} />
        {accountManager && <PersonLabel person={accountManager} />}
        <AddressLabel address={address} />
      </div>
      {mouseCoordinates.mouseX && mouseCoordinates.mouseY ? (
        <ContextMenu
          documentId={document.id}
          mouseX={mouseCoordinates.mouseX}
          mouseY={mouseCoordinates.mouseY}
          onClose={handleMenuClose}
          onOpen={goToConstructionSite}
        />
      ) : null}
    </Paper>
  );
};

interface ConstructionSiteProps {
  result: SearchConstructionSitesResult;
}
