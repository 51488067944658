/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () =>
  makeStyles(
    {
      containedButton: {
        '&:hover': { boxShadow: 'none' },
        borderRadius: '30px',
        boxShadow: 'none',
        height: '32px',
        textTransform: 'capitalize',
      },
      containedButtonDisabled: {
        backgroundColor: '#1DB954',
      },
      multiSourceLogoContainer: {
        '&>div': {
          marginRight: '-8px',
        },
        display: 'flex',
      },
      preconfiguredSourceContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 0px 8px 0px',
        width: '100%',
      },
      preconfiguredSourceNameContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      },
      sourceIconContainer: {
        marginLeft: '12px',
      },
      sourceLogoContainer: {
        alignItems: 'center',
        background: 'white',
        border: '1px solid #FFFFFF',
        borderRadius: '100%',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
        display: 'flex',
        height: '38px',
        justifyContent: 'center',
        width: '38px',
      },
      sourceLogoImage: {
        height: '24px',
        width: '24px',
      },
      sourceName: {
        color: '#545E6A',
        fontSize: '14px',
        fontWeight: 600,
      },
    },
    { index: 1 },
  )();
