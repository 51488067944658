import Menu, { MenuProps } from '@material-ui/core/Menu';
import React from 'react';
import { useStyles } from './styles';

export const StyledMenu: React.FC<MenuProps> = (props) => {
  const classes = useStyles();

  return (
    <Menu
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      classes={{ paper: classes.styleMenuPaper }}
      elevation={0}
      getContentAnchorEl={null}
      transformOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      {...props}
    />
  );
};
