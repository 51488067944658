import React from 'react';
import { CloudOff } from 'tabler-icons-react';

import { Typography } from '../../material';
import { useStyles } from './styles';
import { DocumentsList } from '../DocumentsList';
import { StartUpLoader } from '../StartUpLoader';

import { SignInButtons } from '../SignInButtons';

export const SpotlightBody: React.FC<SpotlightBodyProps> = ({
  isLoggedIn,
  shouldShowStartUpLoader,
}) => {
  const classes = useStyles();

  if (shouldShowStartUpLoader) return <StartUpLoader inSpotlight />;

  if (isLoggedIn) return <DocumentsList inSpotlight />;
  return (
    <div className={classes.notSignedInContainer}>
      <div className={classes.notSignedInSubContainer}>
        <CloudOff color="#ccc" size={64} />
      </div>
      <Typography className={classes.notSignedInTitle}>You are not connected.</Typography>
      <Typography className={classes.notSignedInSubTitle}>
        Please sign in to use Outmind.
      </Typography>
      <div className={classes.notSignedInSubContainer}>
        <SignInButtons />
      </div>
    </div>
  );
};

interface SpotlightBodyProps {
  isLoggedIn: boolean;
  shouldShowStartUpLoader: boolean;
}
