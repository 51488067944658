import { Add } from '@styled-icons/fluentui-system-filled/Add';
import React, { useState } from 'react';

import { Label, useDeleteLabel, useLabelsTree, useTranslations } from '../../hooks';
import { Button, Tooltip, Typography } from '../../material';
import { Actions, useDispatch } from '../../store';
import { ConfirmDeleteDialog } from '../CustomDialog/ConfirmDeleteDialog';
import { LabeledBox } from '../LabeledBox';
import { AddButton } from '../MaterialComponents/AddButton';
import { LabelDialog } from './LabelDialog';
import { LabelTree } from './LabelTree';
import { useStyles } from './styles';

export const Labels: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { data: labels = [] } = useLabelsTree();

  const { t } = useTranslations();

  const [labelToEdit, setLabelToEdit] = useState<Label | undefined>();
  const [labelToDelete, setLabelToDelete] = useState<Label | undefined>();
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleEdit = (label?: Label): void => {
    setLabelToEdit(label);
    setOpen(true);
  };

  const handleCreate = (): void => {
    handleEdit(undefined);
  };

  const handleDelete = (label: Label): void => {
    setLabelToDelete(label);
    setDeleteDialogOpen(true);
  };

  const filterByLabel = (label: Label): void => {
    dispatch(Actions.addLabelFilter(label));
  };

  const { mutate: deleteLabel } = useDeleteLabel();

  return (
    <div className={classes.labelsFilterContainer}>
      <div className={classes.titleContainer}>
        <Typography className={classes.labelHeader}>{t('team_tags')}</Typography>
        <div className={classes.addButtonContainer}>
          <Tooltip aria-label="Add" arrow placement="right" title={t('add_tag')}>
            <AddButton onClick={() => setOpen(true)} />
          </Tooltip>
        </div>
      </div>
      <div className={classes.labelTreeContainer}>
        {labels.length ? (
          <LabelTree
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            labels={labels}
            onClickHandler={filterByLabel}
          />
        ) : (
          <div className={classes.addFirstTagContainer}>
            <Button
              classes={{
                label: classes.addFirstTagButtonLabel,
                root: classes.addFirstTagButtonRoot,
                text: classes.addFirstTagButton,
              }}
              onClick={handleCreate}
              startIcon={<Add size={16} />}
              variant="text"
            >
              {t('create_tag')}
            </Button>
          </div>
        )}
      </div>

      <LabelDialog close={() => setOpen(false)} labelToEdit={labelToEdit} open={open} />
      <ConfirmDeleteDialog
        deleteButtonText={t('delete')}
        dialogTitle={t('delete_tag_title')}
        onClose={() => setDeleteDialogOpen(false)}
        onDeleteConfirmed={() => {
          deleteLabel(
            { labelId: labelToDelete!.id },
            {
              onSuccess: () => {
                dispatch(Actions.notifyLabelDeleted());
              },
            },
          );
        }}
        open={deleteDialogOpen}
        textHelper={t('delete_tag_helper')}
      >
        <LabeledBox
          classes={{
            borderBox: classes.deleteLabelDialogBorderBox,
            boxContainer: classes.deleteLabelDialogBoxContainer,
            boxTitle: classes.deleteLabelDialogBoxTitle,
          }}
          title={t('caution')}
        >
          {t('delete_tag_caution_helper')}
        </LabeledBox>
      </ConfirmDeleteDialog>
    </div>
  );
};
