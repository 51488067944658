import { makeStyles } from '../../material';

const navigationButtonIconBaseStyle = {
  height: '48px',
  width: '48px',
};

export const useStyles = makeStyles(
  {
    chevronButton: { color: '#245EE5' },
    imageLogoContainer: {
      '& > img': { display: 'block', marginLeft: '20px', width: '80px' },
      left: '12px',
      position: 'absolute',
      top: '12px',
    },
    linkItem: {
      display: 'inline-block',
      margin: '12px 4px',
    },
    linksContainer: {
      display: 'block',
    },
    navigationButton: {
      '&:last-child': {
        paddingBottom: '20px',
      },
      alignItems: 'center',
      display: 'flex',
      padding: '20px',
      width: '500px',
    },
    navigationButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    navigationButtonIconMe: {
      ...navigationButtonIconBaseStyle,
      backgroundColor: '#245EE5',
    },
    navigationButtonIconTeam: {
      ...navigationButtonIconBaseStyle,
      backgroundColor: '#40C965',
    },
    navigationButtonIconVip: {
      ...navigationButtonIconBaseStyle,
      backgroundColor: '#F1A208',
    },
    navigationButtonText: {
      color: '#333',
      flex: '1',
      fontSize: '1.125rem',
      paddingLeft: '20px',
      textAlign: 'left',
    },
    navigationPreviousButton: {
      left: '12px',
      position: 'absolute',
      top: '12px',
    },
    navigationSubtitle: {
      color: '#757575',
      fontSize: '1.125rem',
      margin: '6px 0 32px 0',
    },
    navigationTitle: {
      color: '#757575',
      fontSize: '1.825rem',
      margin: '0 0 24px 0',
    },
  },
  { index: 1 },
);
