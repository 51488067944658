import { ExtendedSources, GSuiteSources, O365Sources } from '@outmind/types';
import React from 'react';

import { ConnectorLogos } from '../../../ConnectorLogos';
import { useStyles } from './styles';

/**
 * Renders the source icon
 */
export const SourceIcon: React.FC<SourceIconProps> = ({ source }) => {
  const classes = useStyles();

  if (source === 'o365') {
    return (
      <div className={classes.multiSourceLogoContainer}>
        {O365Sources.map((src, i) => (
          <div className={classes.sourceLogoContainer} style={{ zIndex: 5 - i }}>
            <img alt={src} className={classes.sourceLogoImage} src={ConnectorLogos[src]} />
          </div>
        ))}
      </div>
    );
  }
  if (source === 'gsuite') {
    return (
      <div className={classes.multiSourceLogoContainer}>
        {GSuiteSources.map((src, i) => (
          <div className={classes.sourceLogoContainer} style={{ zIndex: 5 - i }}>
            <img alt={src} className={classes.sourceLogoImage} src={ConnectorLogos[src]} />
          </div>
        ))}
      </div>
    );
  }
  return (
    <div className={classes.sourceLogoContainer}>
      <img alt={source} className={classes.sourceLogoImage} src={ConnectorLogos[source]} />
    </div>
  );
};

interface SourceIconProps {
  source: ExtendedSources;
}
