import { makeStyles } from '../../material';

export const useStyles = makeStyles(
  {
    acceptTerms: {
      alignItems: 'center',
      display: 'flex',
      marginTop: 10,
      maxWidth: 450,
      padding: 10,
      paddingBottom: 0,
    },
    acceptTermsHelper: {
      color: '#333',
      fontSize: '0.75rem',
    },
  },
  { index: 1 },
);
