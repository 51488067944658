import { DocumentIndiceNames, SearchConstructionToolsResult, ToolState } from '@outmind/types';
import moment from 'moment';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useLinkHandler, useTranslations } from '../../hooks';
import { Paper, SearchIcon } from '../../material';
import { Actions, useDispatch } from '../../store';
import { ContextMenu } from '../ContextMenu';
import { ActionButton } from './ActionButton';
import { AgencyLabel } from './AgencyLabel';
import { DateLabel } from './DateLabel';
import { DocumentHeader } from './DocumentHeader';
import { useStyles } from './styles';
import { ToolModelLabel } from './ToolModelLabel';
import { ToolSerialNumberLabel } from './ToolSerialNumberLabel';

/**
 * Displays an ElasticConstructionTool document
 */
export const ConstructionTool: React.FC<ConstructionToolProps> = ({ result }) => {
  const { document, highlights } = result;

  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslations();

  const [isSelected, setIsSelected] = useState(false);

  const [mouseCoordinates, setMouseCoordinates] = useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>({
    mouseX: null,
    mouseY: null,
  });

  const handleRightClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    if (!mouseCoordinates.mouseX && !mouseCoordinates.mouseY) {
      setMouseCoordinates({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
      setIsSelected(true);
    }
  };

  const handleMenuClose = (): void => {
    setIsSelected(false);
    setMouseCoordinates({
      mouseX: null,
      mouseY: null,
    });
  };

  const onclickHandler = (): (() => void) | undefined => {
    if (mouseCoordinates.mouseX && mouseCoordinates.mouseY) return;
    return goToConstructionTool;
  };
  const goToConstructionTool = useLinkHandler(document.accessLink);

  const searchByConstructionToolButton = (
    <div className={classes.actionButtonContainer}>
      <ActionButton
        icon={<SearchIcon fontSize="small" />}
        onClick={() => {
          dispatch(
            Actions.addDocumentRelationFilter({
              document: result.document,
              indexName: DocumentIndiceNames.ConstructionTools,
            }),
          );
          dispatch(Actions.setChosenIndexTab('*'));
        }}
        title={t('search_by_person')}
      />
    </div>
  );

  const displayedTitle = highlights?.title || document.title;
  const agency = {
    address: document.agency.address.city
      ? {
          city: highlights?.['agency.address.city'] ?? document.agency.address.city,
          line1: highlights?.['agency.address.line1'] ?? document.agency.address.line1,
          zipCode: highlights?.['agency.address.zipCode'] ?? document.agency.address.zipCode,
        }
      : undefined,
    code: highlights?.['agency.code'] ?? document.agency.code,
    name: highlights?.['agency.name'] ?? document.agency.name,
  };
  const modelName = highlights?.['model.name'] ?? document.model?.name;
  const serialNumber = highlights?.serialNumber ?? document.serialNumber;
  const { latestAssignment, nextControleDate: nextCheckupDate, toolState } = document;
  const assignmentEndDate = latestAssignment?.endDate;

  const toolStatusTag = (() => {
    switch (toolState) {
      case ToolState.Available:
        return {
          color: '#4CAF4F',
          id: uuid(),
          value: t('available_short').toUpperCase(),
        };
      case ToolState.Unavailable:
        return {
          color: '#FF5721',
          id: uuid(),
          value: `${t('unavailable_short').toUpperCase()} - ${t('return').toUpperCase()} : ${moment(
            assignmentEndDate,
          ).format('DD/MM/YYYY')}`,
        };
      case ToolState.OutOfOrder:
        return {
          color: '#F44335',
          id: uuid(),
          value: t('out_of_order_short').toUpperCase(),
        };
      case ToolState.Decommissioned:
        return {
          color: '#F44335',
          id: uuid(),
          value: t('decommissioned').toUpperCase(),
        };
      default:
        return {
          color: '#4CAF4F',
          id: uuid(),
          value: t('available_short').toUpperCase(),
        };
    }
  })();
  return (
    <Paper
      className={isSelected ? classes.documentContainerClicked : classes.documentContainer}
      elevation={0}
      onClick={onclickHandler()}
      onContextMenu={handleRightClick}
    >
      <DocumentHeader
        accessLink={document.accessLink}
        actionButtons={searchByConstructionToolButton}
        documentId={document.id}
        labels={document.labels}
        source={document.source}
        tag={toolStatusTag}
        title={displayedTitle}
      />
      <div className={classes.documentFooterContainer}>
        <AgencyLabel agency={agency} />
        {modelName?.length && <ToolModelLabel modelName={modelName} />}
        {serialNumber.length > 0 && <ToolSerialNumberLabel serialNumber={serialNumber} />}
        {nextCheckupDate && <DateLabel date={moment(nextCheckupDate)} />}
      </div>
      {mouseCoordinates.mouseX && mouseCoordinates.mouseY ? (
        <ContextMenu
          documentId={document.id}
          mouseX={mouseCoordinates.mouseX}
          mouseY={mouseCoordinates.mouseY}
          onClose={handleMenuClose}
          onOpen={goToConstructionTool}
        />
      ) : null}
    </Paper>
  );
};

interface ConstructionToolProps {
  result: SearchConstructionToolsResult;
}
