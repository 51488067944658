import { Connector } from '@outmind/types';
import React from 'react';
import { useDeleteConnectors, useRuntimeEnv } from '../../../../hooks';
import { CancelConfigureSourceDialog } from './CancelConfigureSourceDialog';

export const CancelConfigureSource: React.FC<CancelConfigureSourceProps> = ({
  connector,
  onClose,
  onDelete,
  showReallyDeleteDialog,
}) => {
  const { inElectron } = useRuntimeEnv();

  const fullyDeleteConnector = (): void => {
    if (inElectron) window.electron?.killLocalDiskUpload(connector.id);

    deleteConnectors([connector]);
  };

  const { mutate: deleteConnectors } = useDeleteConnectors();

  return (
    <CancelConfigureSourceDialog
      onClose={() => onClose()}
      onDeleteConfirmed={() => {
        fullyDeleteConnector();
        onDelete();
      }}
      open={showReallyDeleteDialog}
    />
  );
};

interface CancelConfigureSourceProps {
  connector: Pick<Connector, 'id' | 'source'>;
  onClose: () => void;
  onDelete: () => void;
  showReallyDeleteDialog: boolean;
}
