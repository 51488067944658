import { Button } from '@material-ui/core';
import React from 'react';
import { useTranslations } from '../../hooks';
import { CustomDialog } from '../CustomDialog';
import { useStyles } from './styles';

export const DownloadAppDialog: React.FC<DownloadAppDialogProps> = ({ close, openDialog }) => {
  const classes = useStyles();
  const { t } = useTranslations();

  return (
    <CustomDialog
      onClose={() => close()}
      open={openDialog}
      title={t('localfile_with_outmind_app_only')}
    >
      <p className={classes.downloadSubtitle}>{t('use_outmind_app_for_localfiles')}</p>
      <div className={classes.downloadButton}>
        <Button color="primary" href="outmind://" variant="contained">
          {`${t('open_desktop').toUpperCase()}`}
        </Button>
      </div>
    </CustomDialog>
  );
};

interface DownloadAppDialogProps {
  close: () => void;
  openDialog: boolean;
}
