import { Blob } from '@outmind/helpers';
import React from 'react';

import { useStyles } from './styles';

/**
 * Displays the thumbnail of the ElasticDoc from its "thumbnail" property
 */
export const DocumentThumbnail: React.FC<DocumentThumbnailProps> = ({
  isSimilarFile,
  thumbnail,
}) => {
  const classes = useStyles({ isSimilarFile });

  const thumbnailBuffer =
    thumbnail && !thumbnail.includes('thumbnail') ? Blob.b64toBlob(thumbnail) : undefined;

  const thumbnailUrl = thumbnailBuffer ? URL.createObjectURL(thumbnailBuffer) : thumbnail;

  return thumbnailUrl ? (
    <div className={classes.thumbnailContainer}>
      <img alt="" className={classes.thumbnailImg} src={thumbnailUrl} />
    </div>
  ) : (
    <div className={classes.thumbnailPlaceholder} />
  );
};

interface DocumentThumbnailProps {
  isSimilarFile: boolean | undefined;
  thumbnail: string | undefined;
}
