import React from 'react';
import { IconButton, Tooltip } from '../../material';
import { useStyles } from './styles';

/**
 * Render the action button  with Tooltip
 */
export const ActionButton: React.FC<ActionButtonProps> = ({ title, icon, onClick }) => {
  const classes = useStyles();

  const handleClick = (e: React.MouseEvent): void => {
    onClick();
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Tooltip className={classes.actionButtonContainer} placement="right" title={title}>
      <IconButton onClick={(e) => handleClick(e)} size="small">
        {icon}
      </IconButton>
    </Tooltip>
  );
};

interface ActionButtonProps {
  icon: JSX.Element;
  onClick: () => void;
  title: string;
}
