import React from 'react';
import Media from 'react-media';
import { useStyles } from './styles';

export const DisplayOnComputerOnly: React.FC<DisplayOnComputerOnlyProps> = (props) => {
  const classes = useStyles(props);
  const { children } = props;

  return (
    <Media query="(max-width: 959px)">
      {(matches) => (!matches ? children : <div className={classes.inResponsive}>{children}</div>)}
    </Media>
  );
};

interface DisplayOnComputerOnlyProps {
  children: React.ReactNode;
}
