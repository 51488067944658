import React from 'react';
import { Dialog, DialogContent, DialogProps } from '../../../material';
import { useStyles } from './styles';

export const PreviewDialog: React.FC<CustomDialogProps> = ({ children, onClose, open }) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.previewDialogPaper, paperWidthMd: classes.maxWidth }}
      maxWidth="lg"
      onClose={onClose}
      open={open}
    >
      <DialogContent className={classes.previewDialogContent}>{children}</DialogContent>
    </Dialog>
  );
};

interface CustomDialogProps extends DialogProps {
  onClose: () => void;
  open: boolean;
}
