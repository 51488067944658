import React, { memo } from 'react';

import { Label } from '../../../hooks';
import { LabelChip } from '../LabelChip';

const LabelChipListNP: React.FC<LabelChipListProps> = ({ labels, onClickLabel, onDeleteLabel }) => {
  const labelChips = labels.map((label) => (
    <LabelChip key={label.id} label={label} onClick={onClickLabel} onDelete={onDeleteLabel} />
  ));

  return <>{labelChips}</>;
};

interface LabelChipListProps {
  labels: Label[];
  onClickLabel?: (label: Label, e?: React.MouseEvent<HTMLDivElement>) => void;
  onDeleteLabel?: (label: Label, e?: React.MouseEvent<HTMLDivElement>) => void;
}

export const LabelChipList = memo(LabelChipListNP);
