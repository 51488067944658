import { makeStyles } from '../../../material';

const baseInfoText = {
  color: '#888',
  fontSize: '0.75rem',
};

export const useStyles = makeStyles(
  {
    cardContentRoot: {
      '&:hover': {
        '& .addLabelButton': {
          opacity: '1 !important',
        },
        backgroundColor: '#E7F0FE',
      },
      '&:last-child': {
        padding: '16px',
      },
    },
    cardRoot: {
      borderRadius: '10px',
      boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.08)',
    },
    connectorLogo: {
      maxHeight: '100%',
      padding: '2px',
      width: '18px',
    },
    contentContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between' },
    dateContainer: { display: 'flex' },
    dateText: {
      ...baseInfoText,
      whiteSpace: 'nowrap',
    },
    fileLogo: { color: 'white', fontSize: '0.9rem', marginRight: '4px', paddingLeft: '8px' },
    hovered: { backgroundColor: '#F4F8FF' },
    imageContainer: { display: 'flex', height: 'inherit' },
    imageResponsiveContainer: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      maxWidth: '100%',
      overflow: 'hidden',
    },
    labelsContainer: {
      display: 'flex',
      margin: '8px 0px 0px -12px',
      maxWidth: '100%',
    },
    ownerContainer: { display: 'flex', width: '55%' },
    ownerIcon: {
      color: '#888',
      marginRight: '5px',
    },
    ownerText: {
      ...baseInfoText,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    pageContainer: { padding: '8px' },
    pageIndex: {
      bottom: '14px',
      position: 'absolute',
      right: '14px',
    },
    pageIndexContainer: {
      alignItems: 'center',
      background: '#245EE5',
      borderRadius: '6px',
      display: 'flex',
      height: '24px',
      justifyContent: 'center',
      width: 'fit-content',
    },
    pageIndexText: {
      color: 'white',
      fontSize: '0.8rem',
      fontWeight: 600,
      paddingRight: '8px',
    },
    pageInfoContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '8px',
      maxWidth: '100%',
    },
    previewButtonContainer: {
      '&& > button': { marginLeft: 'unset' },
      background: 'white',
      borderRadius: '100%',
      boxShadow: '3px 3px 15px -5px rgba(0,0,0,0.4)',
      padding: '4px',
      position: 'absolute',
      right: '8px',
      top: '8px',
    },
    resultElementFileType: {
      height: 'auto',
      marginRight: '5px',
      width: '18px',
    },

    smartLink: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    thumbnail: { maxHeight: '100%', maxWidth: '100%', transition: 'all 0.3s ease' },
    thumbnailContainer: {
      alignItems: 'center',
      background: '#F6F6F6',
      display: 'flex',
      height: '240px',
      justifyContent: 'center',
      overflow: 'hidden',
      position: 'relative',
    },
    title: {
      color: '#333333',
      fontSize: '0.875rem',
      fontWeight: 600,
      marginLeft: '8px',
      maxWidth: 'calc(100% - 36px)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    titleContainer: { alignItems: 'center', display: 'flex', maxWidth: '100%' },
  },
  { index: 1 },
);
