/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Source } from '@outmind/types';
import { action } from 'typesafe-actions';

export enum ActionTypes {
  NOTIFY_CONNECTOR_CREATION_FAILED = 'NOTIFY_CONNECTOR_CREATION_FAILED',
  NOTIFY_CONNECTOR_DELETION_STARTED = 'NOTIFY_DELETE_CONNECTOR_STARTED',
  NOTIFY_CONNECTOR_DELETION_SUCCESS = 'NOTIFY_DELETE_CONNECTOR_SUCCESS',
  NOTIFY_CREDENTIALS_UPDATE_FAILED = 'NOTIFY_CREDENTIALS_UPDATE_FAILED',
  NOTIFY_CREDENTIALS_UPDATE_SUCCESS = 'NOTIFY_CREDENTIALS_UPDATE_SUCCESS',
  NOTIFY_LABEL_CREATED = 'NOTIFY_LABEL_CREATED',
  NOTIFY_LABEL_DELETED = 'NOTIFY_LABEL_DELETED',
  NOTIFY_LABEL_UPDATED = 'NOTIFY_LABEL_UPDATED',
  NOTIFY_SEARCH_REFRESHED = 'NOTIFY_SEARCH_REFRESHED',
  NOTIFY_SOURCE_SHARED = 'NOTIFY_SOURCE_SHARED',
  NOTIFY_START_SYNC_FAILED = 'NOTIFY_START_SYNC_FAILED',
  NOTIFY_UNAUTHORIZED_ACCESS = 'NOTIFY_UNAUTHORIZED_ACCESS',
}

export const Actions = {
  notifyConnectorCreationFailed: () => action(ActionTypes.NOTIFY_CONNECTOR_CREATION_FAILED),
  notifyConnectorDeletionStarted: () => action(ActionTypes.NOTIFY_CONNECTOR_DELETION_STARTED),
  notifyConnectorDeletionSuccess: (source: Source) =>
    action(ActionTypes.NOTIFY_CONNECTOR_DELETION_SUCCESS, source),
  notifyCredentialsUpdateFailed: (source: Source) =>
    action(ActionTypes.NOTIFY_CREDENTIALS_UPDATE_FAILED, source),
  notifyCredentialsUpdateSuccess: (source: Source) =>
    action(ActionTypes.NOTIFY_CREDENTIALS_UPDATE_SUCCESS, source),
  notifyLabelCreated: () => action(ActionTypes.NOTIFY_LABEL_CREATED),
  notifyLabelDeleted: () => action(ActionTypes.NOTIFY_LABEL_DELETED),
  notifyLabelUpdated: () => action(ActionTypes.NOTIFY_LABEL_UPDATED),
  notifySearchRefreshed: () => action(ActionTypes.NOTIFY_SEARCH_REFRESHED),
  notifySourceShared: () => action(ActionTypes.NOTIFY_SOURCE_SHARED),
  notifyStartSyncFailed: () => action(ActionTypes.NOTIFY_START_SYNC_FAILED),
  notifyUnauthorizedAccess: () => action(ActionTypes.NOTIFY_UNAUTHORIZED_ACCESS),
};
