import React from 'react';
import { ChevronLeft } from 'tabler-icons-react';

import outmindLogo from '../../assets/outmind-logo.png';
import { useSignOut, useTranslations } from '../../hooks';
import { IconButton } from '../../material';
import { BaseNavigationButton } from '../BaseNavigationButton';
import { WelcomeView } from '../WelcomeView';
import { useStyles } from './styles';

export const RegisterView: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslations();

  const { mutate: signOut } = useSignOut();

  return (
    <WelcomeView withFooter>
      <div className={classes.imageLogoContainer}>
        <img alt="outmind-logo" src={outmindLogo} />
        <IconButton className={classes.chevronButton} onClick={signOut}>
          <ChevronLeft />
        </IconButton>
      </div>
      <div className={classes.navigationTitle}>{t('register_view_title')}</div>
      <div className={classes.linksContainer}>
        <div className={classes.linkItem}>
          <BaseNavigationButton text={t('i_have_vip_code')} to="/register/vip" />
        </div>
        <div className={classes.linkItem}>
          <BaseNavigationButton
            target="_blank"
            text={t('see_pricing')}
            to={{ pathname: 'https://www.outmind.ai/price' }}
          />
        </div>
        <div className={classes.linkItem}>
          <BaseNavigationButton
            target="_blank"
            text={t('talk_to_us')}
            to={{ pathname: 'https://www.outmind.ai/contact' }}
          />
        </div>
      </div>
    </WelcomeView>
  );
};
