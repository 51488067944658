import React from 'react';
import { Tool as Icon } from 'tabler-icons-react';
import { useTranslations } from '../../../hooks';
import { SanitizedHtmlTypography } from '../../SanitizedHtmlTypography';
import { useStyles } from './styles';

export const ToolModelLabel: React.FC<ToolModelLabelProps> = ({ modelName }) => {
  const classes = useStyles();
  const { t } = useTranslations();

  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <Icon className={classes.icon} size="14" />
      </div>
      <SanitizedHtmlTypography
        className={classes.label}
        html={`${t('model')} : ${modelName}`}
        variant="body2"
      />
    </div>
  );
};

interface ToolModelLabelProps {
  modelName: string;
}
